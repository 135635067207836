//* third party packages
import React from "react";

//* components
import MainApp from "components/Apps/Main";

//* css
import "normalize.css";

const AppLoader: React.FunctionComponent = () => {
  return <MainApp />;
};

export default AppLoader;
