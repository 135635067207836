/**
 *
 * @param selectorString
 * @param horizontal
 * @param center
 */
const scrollIntoView = (
  selectorString: string,
  horizontal: boolean,
  center: boolean
) => {
  const exhibitionWall = document.querySelector(".simplebar-content-wrapper");
  const exhibitionWallElement = document.querySelector(selectorString);
  if (exhibitionWall && exhibitionWallElement) {
    if (horizontal) {
      exhibitionWall.scrollLeft =
        (exhibitionWallElement as HTMLElement).offsetLeft +
        (center
          ? (exhibitionWallElement as HTMLElement).offsetWidth / 2 -
            (exhibitionWall as HTMLElement).offsetWidth / 2
          : 0);
    } else {
      exhibitionWall.scrollTop =
        (exhibitionWallElement as HTMLElement).offsetTop +
        (center
          ? (exhibitionWallElement as HTMLElement).offsetHeight / 2 -
            (exhibitionWall as HTMLElement).offsetHeight / 2
          : 0);
    }
  }
};

export default scrollIntoView;
