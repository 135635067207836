import * as s from "components/Apps/Main/Navbar/FilterMenu.styled";
import styled from "styled-components/macro";
import { SVGDirectHover } from "components/Apps/Main/styles";

export const NavbarContainer = styled.nav`
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  @media (max-width: 991px) {
    height: 70px;
  }
`;

export const NavbarContent = styled.div`
  display: flex;
  flex: 1;
  height: 30.2px;
  justify-content: space-between;
  margin: 0 45px;
  @media (max-width: 991px) {
    margin: 0 22px;
  }
`;

export const LogoContainer = styled.div`
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
`;

export const MainNavContainer = styled.div`
  align-items: center;
  display: flex;
  font-family: "Open-Sans", serif;
  margin: 0 32px;
  & > :not(:last-child) {
    margin-right: 32px;
  }
  @media (min-width: 1200px) {
    flex: 1;
    justify-content: space-around;
    margin: 0 32px;
    max-width: 750px;
    & > :not(:last-child) {
      margin-right: initial;
    }
  }
  @media (max-width: 991px) {
    margin-right: initial;
  }
`;

export const FilterContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const FilterText = styled.div`
  display: flex;
  font-family: "Open-Sans Bold", serif;
  font-size: 15px;
  line-height: 14px;
  margin-right: 32px;
  text-align: right;
  width: 93px;
  @media (max-width: 575px) {
    margin-right: 12px;
  }
`;

export const FilterIcon = styled.div`
  height: 30px;
  width: 34px;
  @media (max-width: 991px) {
    cursor: pointer;
  }
  & > svg {
    height: 100%;
    width: 100%;
  }
`;

export const MainNavElement = styled.a<{ active: boolean }>`
  text-decoration: ${({ active }) => (active ? "underline" : "none")};
  text-underline-offset: 5px;
  cursor: pointer;
  display: block;
  font-family: ${({ active }) =>
    active ? '"Open-Sans Bold", serif' : '"Open-Sans", serif'};
  font-size: 18px;
  line-height: 24.51px;
  text-align: center;
  white-space: nowrap;
  @media (max-width: 1120px) and (min-width: 992px) {
    font-size: 14px !important;
  }
  &:after {
    color: transparent;
    content: attr(title);
    display: block;
    font-family: "Open-Sans Bold", serif;
    height: 1px;
    overflow: hidden;
    visibility: hidden;
  }
  //transform: translateZ(0) scale(1, 1) !important;
  &:hover {
    font-family: "Open-Sans Bold", serif;
  }
  @media (max-width: 991px) {
    display: none;
  }
  ${s.LinkContainer} & {
    display: block;
    font-size: 32px;
    line-height: 43.58px;
    @media (max-width: 991px) {
      line-height: 43.58px;
      font-size: 32px;
    }
    @media (max-width: 575px) {
      line-height: 24.51px;
      font-size: 18px;
    }
  }
`;

export const ButtonsRightContainer = styled.div`
  ${SVGDirectHover};
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 85px;
  & a {
    align-items: center;
    display: flex;
    flex: 1;
    height: 30.2px;
  }
  @media (max-width: 991px) {
    justify-content: flex-end;
    width: auto;
  }
`;
