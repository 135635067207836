import SimpleBar from "simplebar-react";
import styled from "styled-components/macro";
import { SVGHover } from "components/Apps/Main/styles";

export const TextPageContentContainer = styled.section`
  margin-top: var(--text-page-margin-top);
  margin-right: var(--text-page-margin-right);
  margin-bottom: var(--text-page-margin-bottom);
  margin-left: var(--text-page-margin-left);
  height: var(--text-page-content-container-height);
  width: calc(
    100% - var(--text-page-margin-right) - var(--text-page-margin-left)
  );
`;

export const TextPageScrollContainer = styled(SimpleBar)`
  height: calc(
    var(--text-page-content-container-height) - var(--text-page-headline-height)
  );
  width: 100%;
`;

/**
 *
 *
 *
 *
 *
 *
 */
export const ImagePageContentContainer = styled.section`
  align-items: center;
  display: flex;
  height: calc(var(--doc-height) - var(--navbar-height) - var(--footer-height));
  justify-content: center;
  width: calc(100%);
  @media (max-width: 991px) {
    height: calc(
      var(--doc-height) - var(--navbar-height) - var(--footer-height) - 20px
    );
    display: block;
  }
`;

export const ImagePageScrollContainer = styled(SimpleBar)`
  height: calc(var(--doc-height) - var(--navbar-height) - var(--footer-height));
  width: calc(100%);
  @media (max-width: 991px) {
  }
`;

export const ImagePageContentWrapper = styled.div`
  display: flex;
  @media (max-width: 991px) {
    margin-top: 20px;
    flex-direction: column;
  }
  @media (max-width: 575px) {
    margin-top: 0;
  }
`;

/**
 *
 *
 *
 *
 *
 *
 */
export const BiografieImageWrapper = styled.div`
  width: 431px;
  height: 573px;
  @media (max-width: 991px) {
    height: 467.975px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  @media (max-width: 575px) {
    width: 100%;
    height: 324.232px;
    display: flex;
    justify-content: flex-end;
  }
`;

export const BiografieImage = styled.img`
  width: 431px;
  height: 573px;
  @media (max-width: 991px) {
    width: 352px;
    height: 467.975px;
    margin-right: 22px;
  }
  @media (max-width: 575px) {
    width: 243.88px;
    height: 324.232px;
    margin-right: 19px;
  }
`;

export const BiografieVideoWrapper = styled.div`
  position: relative;
  height: 573px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 991px) {
    height: 467.975px;
    margin-right: 22px;
  }
  @media (max-width: 575px) {
    height: 324.232px;
    margin-right: 19px;
  }
`;

export const BiografieVideo = styled.video`
  height: 100%;
  object-fit: cover;
  object-position: center;
  max-width: 100%;
`;

export const BiografieVolumeButton = styled.button`
  background: none;
  border: none;
  bottom: 6px;
  cursor: pointer;
  height: 61.6px;
  position: absolute;
  right: 10px;
  width: 64.8px;
  & > svg {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 991px) {
    height: 44.8px;
    width: 47.2px;
  }
`;

export const BiografieTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 61px;
  max-width: 350px;
  & h1 {
    margin: 0;
    height: initial;
  }
  @media (max-width: 991px) {
    max-width: 340px;
    margin-left: 108px;
    & h1 {
      margin: 0 0 30px 0;
    }
  }
  @media (max-width: 575px) {
    max-width: 340px;
    margin-left: 18px;
    margin-top: 10px;
    & h1 {
      max-width: 200px;
      margin: 0 0 20px 0;
    }
  }
  @media (max-height: 739px) {
    margin-bottom: 80px;
  }
`;

export const BiografieText = styled.div`
  width: 340px;
  font-family: "Open-Sans", serif;
  font-size: 11px;
`;

export const BiografieTextScrollContainer = styled(SimpleBar)`
  height: 450px;
  width: calc(100%);
  @media (max-width: 991px) {
    height: auto;
  }
  & h3 {
    font-size: 11px;
    font-weight: bold;
  }
`;

export const YearAndText = styled.div`
  display: flex;
`;

export const Year = styled.div`
  font-weight: bold;
  margin-right: 10px;
`;

export const YearText = styled.div`
  margin-bottom: 5px;
`;

/**
 *
 *
 *
 *
 *
 *
 */
export const KontaktImageWrapper = styled.div`
  @media (max-width: 991px) {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  @media (max-width: 575px) {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
`;

export const KontaktImage = styled.img`
  height: 565px;
  @media (max-width: 991px) {
    height: 377px;
    margin-left: 22px;
  }
  @media (max-width: 575px) {
    height: 266px;
    margin-left: initial;
  }
`;

export const KontaktTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: 61px;
  & h1 {
    margin: 0;
    @media (max-width: 575px) {
      margin: 0 0 30px 0;
    }
  }
  @media (max-width: 991px) {
    max-width: 340px;
    margin-left: 170px;
    margin-top: 40px;
    height: 380px;
  }
  @media (max-width: 575px) {
    max-width: 340px;
    margin-left: 64px;
    height: 360px;
  }
`;

export const KontaktLinkWrapper = styled.div`
  display: flex;
  @media (max-width: 575px) {
    margin-top: 20px;
  }
`;

export const KontaktWennDuWrapper = styled.div`
  max-width: 280px;
  font-family: "Open-Sans", serif;
  font-size: 11px;
  margin-bottom: 30px;
  @media (max-width: 575px) {
    margin-bottom: 0;
  }
`;

export const KontaktImpressumWrapper = styled.div`
  max-width: 280px;
  font-family: "Open-Sans", serif;
  font-size: 11px;
  margin-top: 20px;
`;

export const KontaktIconContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 97.5px;
  width: 20px;
  & svg {
    height: 100%;
  }
  & a {
    align-items: center;
    display: flex;
    height: 32px;
  }
`;
export const KontaktIconTextContainer = styled.div`
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  font-family: "Open-Sans", serif;
  font-size: 18px;
  height: 97.5px;
  margin-left: 12px;
  & div {
    align-items: center;
    display: flex;
    height: 32px;
  }
  & a {
    text-decoration: none;
  }
`;

/**
 *
 *
 *
 *
 *
 *
 */
export const TextPageHeadline = styled.h1`
  font-family: "Open-Sans", serif;
  font-size: 28px;
  line-height: 38.13px;
  margin: 0 0 15px 0 !important;
  -webkit-text-size-adjust: 100%;
  height: var(--text-page-headline-height);
  @media (max-width: 991px) {
    //font-size: 60px;
    //line-height: 52.2px;
  }
  @media (max-width: 575px) {
    //font-size: 40px;
    //line-height: 45.24px;
  }
`;

export const ImpressumParagraph = styled.p`
  font-family: "Open-Sans", serif;
  font-size: 11px;
  line-height: 14.98px;
  max-width: 510px;
  @media (max-width: 1200px) {
    margin-left: 50px;
  }
  @media (max-width: 575px) {
    margin-left: 0;
  }
`;

export const TwoColWrapper = styled.div`
  display: flex;
  @media (max-width: 1200px) {
    flex-direction: column;
    margin-left: 50px;
  }
  @media (max-width: 575px) {
    flex-direction: column;
    margin-left: 0;
    padding-bottom: 80px;
  }
`;

export const TwoColParagraph = styled.p`
  font-family: "Open-Sans", serif;
  font-size: 11px;
  line-height: 14.98px;
  max-width: 610px;
  margin-right: 30px;
  & > b {
    font-family: "Open-Sans Bold", serif;
  }
  @media (max-width: 991px) {
  }
`;

/**
 * back-to things
 */
export const BackArrowContainer = styled.div`
  ${SVGHover};
  align-items: center;
  bottom: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: "Open-Sans", serif;
  font-size: 18px;
  justify-content: center;
  line-height: 24.51px;
  position: absolute;
  right: 40px;
  width: 72px;
  & svg {
    height: 50px;
    width: 32px;
  }
  & div {
    margin-top: 10px;
  }
  @media (min-width: 992px) {
    display: none;
  }
`;
