//* third party packages
import React from "react";
import { useDraggable } from "react-use-draggable-scroll";

//* components
import * as s from "components/Apps/Main/Components/ExhibitionWall/ExhibitionWall.styled";
import { BodyBackgroundWall } from "components/Apps/Main/styles";

//* other
import useRecursiveTimeout from "components/Apps/Main/Helper/useRecursiveTimeout";
import { Context } from "components/Apps/Main/Context";

export const ExhibitionWall: React.FunctionComponent<
  TReactPropsWithChildren
> = (props) => {
  const {
    breakPoints: { isDesktop },
    exhibitionWallRef,
  } = React.useContext(Context);
  const [isMounted] = React.useState(true);
  const scrollbarRef = React.useRef<any>();
  const { events } = useDraggable(
    exhibitionWallRef as React.MutableRefObject<HTMLDivElement>,
    {
      applyRubberBandEffect: true,
      isMounted,
    }
  );

  useRecursiveTimeout(() => {
    window.dispatchEvent(new Event("resize"));
  }, 500);

  //* avoid useDraggable hook to interfere with SimpleBar when dragging the custom scrollbar
  //* -> do not fire useDraggable's mousedown-handler if mousedown happens on the scrollbar
  const mouseDownHandler = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const { right, bottom } = e.currentTarget.getBoundingClientRect();
      if (e.clientY < bottom - 10 && e.clientX < right - 10) {
        events.onMouseDown(e);
      }
    },
    [events]
  );

  //* ensure recalculation of Simplebar
  React.useEffect(() => {
    if (scrollbarRef.current) {
      const handleResize = () => {
        scrollbarRef.current.recalculate();
      };
      window.addEventListener("resize", handleResize);
      window.addEventListener("orientationchange", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("orientationchange", handleResize);
      };
    }
    return undefined;
  }, [scrollbarRef]);

  const wheelHandler = React.useCallback(
    (e: React.WheelEvent) => {
      if (exhibitionWallRef.current) {
        exhibitionWallRef.current.scrollLeft += e.deltaY;
      }
    },
    [exhibitionWallRef]
  );

  return (
    <>
      <BodyBackgroundWall />
      <s.ExhibitionWallContainer
        autoHide={!isDesktop}
        clickOnTrack={false}
        id={"exhibition-wall"}
        onMouseDown={mouseDownHandler}
        onWheel={wheelHandler}
        ref={scrollbarRef}
        scrollableNodeProps={{ ref: exhibitionWallRef }}
        $showTrack={isDesktop}
      >
        <s.Wall>{props.children}</s.Wall>
      </s.ExhibitionWallContainer>
    </>
  );
};

export default ExhibitionWall;
