//* third party packages
import React from "react";
import { useClickAway, useKeyPressEvent } from "react-use";
import { useNavigate, useMatch } from "react-router-dom";

//* components
import * as menu from "components/Apps/Main/Components/MenuOverlay.styled";
import * as s from "components/Apps/Main/Menu/Menu.styled";
import Footer from "components/Apps/Main/Components/Footer";
import FooterTablet from "components/Apps/Main/Components/Footer/FooterTablet";
// import Icons from "components/Apps/Main/Components/Footer/Icons";
// import Lamp from "components/Apps/Main/Components/Lamp";

//* other
import routes from "constants/routes";
import { Context } from "components/Apps/Main/Context";
// import { ReactComponent as FrackmannLogo } from "assets/Frackmann_logo_off-black.svg";
import { ReactComponent as X } from "assets/icons/X.svg";

const Menu: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { menuOpen, setImageDetailId, setMenuOpen, toggleMenuOpen } =
    React.useContext(Context);
  const ref = React.useRef<HTMLElement>(null);
  useClickAway(ref, () => {
    setMenuOpen(false);
  });
  useKeyPressEvent("Escape", () => setMenuOpen(false));
  return (
    <menu.MenuContainer open={menuOpen} ref={ref}>
      <menu.LogoContainer
        title={"Frackmann"}
        onClick={() => navigate(routes.home)}
      >
        Harald
        <br />
        Frackmann
        {/*<FrackmannLogo*/}
        {/*  title={"Frackmann"}*/}
        {/*  onClick={() => navigate(routes.home)}*/}
        {/*/>*/}
      </menu.LogoContainer>
      <menu.CloseContainer title={"Menü schließen"}>
        <X onClick={toggleMenuOpen} />
      </menu.CloseContainer>
      <s.LinkContainer>
        <s.NavElement
          active={!!useMatch(routes.home)}
          onClick={() => {
            navigate(routes.home);
            setMenuOpen(false);
            setImageDetailId(null);
          }}
          title={"Werkauswahl"}
        >
          Werkauswahl
        </s.NavElement>
        <s.TinyLinkContainer>
          <s.TinyNavElement
            active={!!useMatch(routes.gallery19701980)}
            onClick={() => {
              navigate(routes.gallery19701980);
              setMenuOpen(false);
              setImageDetailId(null);
            }}
            title={"1970-1980"}
          >
            1970-1980
          </s.TinyNavElement>
          <s.TinyNavElement
            active={!!useMatch(routes.gallery19801990)}
            onClick={() => {
              navigate(routes.gallery19801990);
              setMenuOpen(false);
              setImageDetailId(null);
            }}
            title={"1980-1990"}
          >
            1980-1990
          </s.TinyNavElement>
          <s.TinyNavElement
            active={!!useMatch(routes.gallery19902000)}
            onClick={() => {
              navigate(routes.gallery19902000);
              setMenuOpen(false);
              setImageDetailId(null);
            }}
            title={"1990-2000"}
          >
            1990-2000
          </s.TinyNavElement>
          <s.TinyNavElement
            active={!!useMatch(routes.gallery20002020)}
            onClick={() => {
              navigate(routes.gallery20002020);
              setMenuOpen(false);
              setImageDetailId(null);
            }}
            title={"2000-2020"}
          >
            2000-2020
          </s.TinyNavElement>
        </s.TinyLinkContainer>
        <s.NavElement
          active={!!useMatch(routes.biografie)}
          onClick={() => {
            navigate(routes.biografie);
            setMenuOpen(false);
            setImageDetailId(null);
          }}
          title={"Biografie"}
        >
          Biografie
        </s.NavElement>
        <s.NavElement
          active={!!useMatch(routes.kontakt)}
          onClick={() => {
            navigate(routes.kontakt);
            setMenuOpen(false);
            setImageDetailId(null);
          }}
          title={"Kontakt"}
        >
          Kontakt
        </s.NavElement>
        <s.NavElement
          active={!!useMatch(routes.literatur)}
          onClick={() => {
            navigate(routes.literatur);
            setMenuOpen(false);
            setImageDetailId(null);
          }}
          title={"Literatur"}
        >
          Literatur
        </s.NavElement>
        <s.NavElement
          active={!!useMatch(routes.datenschutz)}
          onClick={() => {
            navigate(routes.datenschutz);
            setMenuOpen(false);
            setImageDetailId(null);
          }}
          title={"Datenschutz"}
        >
          Datenschutz
        </s.NavElement>
        {/*<s.TinyLinkContainer>*/}
        {/*<s.TinyNavElement*/}
        {/*  active={!!useMatch(routes.impressum)}*/}
        {/*  onClick={() => {*/}
        {/*    navigate(routes.impressum);*/}
        {/*    setMenuOpen(false);*/}
        {/*    setImageDetailId(null);*/}
        {/*  }}*/}
        {/*  title={"Impressum"}*/}
        {/*>*/}
        {/*  Impressum*/}
        {/*</s.TinyNavElement>*/}
        {/*<s.TinyNavElement*/}
        {/*  active={!!useMatch(routes.datenschutz)}*/}
        {/*  onClick={() => {*/}
        {/*    navigate(routes.datenschutz);*/}
        {/*    setMenuOpen(false);*/}
        {/*    setImageDetailId(null);*/}
        {/*  }}*/}
        {/*  title={"Datenschutz"}*/}
        {/*>*/}
        {/*  Datenschutz*/}
        {/*</s.TinyNavElement>*/}
        {/*</s.TinyLinkContainer>*/}
        {/*{breakPoints.isDesktop && <Icons />}*/}
        {/*<Lamp menu={true} />*/}
      </s.LinkContainer>
      <Footer />
      <FooterTablet />
    </menu.MenuContainer>
  );
};

export default Menu;
