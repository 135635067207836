//* third party packages
import React from "react";

//* components
import * as sd from "components/Apps/Main/Gallery/CollectionRendererGrid.styled";

//* other
import imageContextMenuHandler from "components/Apps/Main/Helper/imageContextMenuHandler";
import scrollIntoView from "components/Apps/Main/Helper/scrollIntoView";
import useGetGalleryImagePath from "components/Apps/Main/Helper/useGetGalleryImagePath";
import { Context } from "components/Apps/Main/Context";
import { ICollectionByFilter } from "assets/gallery/database-v2";

//* local types
type TProps = {
  collectionByFilter: ICollectionByFilter;
  updateGroupInformation: boolean;
};

const CollectionRendererGrid: React.FunctionComponent<TProps> = ({
  collectionByFilter,
  updateGroupInformation,
}) => {
  const {
    setImageDetailId,
    breakPoints,
    setCurrentGroup,
    scrollToGroupName,
    setScrollToGroupName,
  } = React.useContext(Context);

  React.useEffect(() => {
    if (scrollToGroupName.length) {
      setTimeout(
        () =>
          requestAnimationFrame(() => {
            setScrollToGroupName("");
            scrollIntoView(
              `div[data-collection-name="${scrollToGroupName}"]`,
              breakPoints.isDesktop,
              false
            );
          }),
        300
      );
    }
  }, [scrollToGroupName, setScrollToGroupName, breakPoints.isDesktop]);

  const getGalleryImagePath = useGetGalleryImagePath();

  const imageClickHandler = React.useCallback(
    (e: React.MouseEvent<HTMLImageElement>) => {
      setImageDetailId(e.currentTarget.dataset.imageName ?? null);
    },
    [setImageDetailId]
  );

  const gridRefs = React.useRef<any[]>([]);

  React.useEffect(() => {
    if (!updateGroupInformation) {
      setCurrentGroup("");
    }
  }, [updateGroupInformation, setCurrentGroup]);

  const observers: IntersectionObserver[] = React.useMemo(
    () =>
      updateGroupInformation
        ? collectionByFilter.collection.map(
            () =>
              new IntersectionObserver(
                ([entry]) => {
                  if (entry.isIntersecting) {
                    setCurrentGroup(
                      (entry.target as HTMLDivElement).dataset.collectionName ??
                        ""
                    );
                  }
                },
                {
                  /* prettier-ignore */
                  rootMargin: `0px -${window.innerWidth / 2 - 10}px 0px -${window.innerWidth / 2 - 10}px`,
                  root: document,
                }
              )
          )
        : [],
    [collectionByFilter.collection, setCurrentGroup, updateGroupInformation]
  );

  React.useEffect(() => {
    gridRefs.current = collectionByFilter.collection.map(
      (c, i) => gridRefs.current[i] ?? React.createRef()
    );
  }, [collectionByFilter.collection, gridRefs]);

  React.useEffect(() => {
    gridRefs.current.forEach((ref, i) => {
      if (ref && observers[i]) {
        observers[i].observe(ref);
      }
    });
  }, [gridRefs, observers]);

  React.useEffect(() => {
    if (updateGroupInformation) {
      setTimeout(() => {
        setCurrentGroup(collectionByFilter.collection[0].group.name);
      }, 300);
    }
  }, [setCurrentGroup, collectionByFilter, updateGroupInformation]);

  return (
    <>
      {collectionByFilter.collection.map((collection, i) => {
        const grid = breakPoints.isDesktop
          ? collection.gridHorizontal
          : breakPoints.isTablet
          ? collection.gridVerticalTablet
          : collection.gridVerticalMobile;
        return (
          <sd.Grid
            className={collectionByFilter.meta.className}
            data-collection-name={collection.group.name}
            grid={grid}
            key={i}
            ref={(el) => (gridRefs.current[i] = el)}
          >
            {/*<sd.CollectionMetaInformation grid={grid}>*/}
            {/*  <p>{collection.group.name}</p>*/}
            {/*  <p>{collection.group.description}</p>*/}
            {/*</sd.CollectionMetaInformation>*/}
            {collection.images.map((image, ii) => {
              const sh = Array.isArray(collection.scaleHorizontal)
                ? collection.scaleHorizontal[ii]
                : collection.scaleHorizontal;
              const svTM = breakPoints.isTablet
                ? collection.scaleVerticalTablet
                : breakPoints.isMobile
                ? collection.scaleVerticalMobile
                : undefined;
              const imageSubPath = Array.isArray(collection.imagesSubPath)
                ? collection.imagesSubPath[ii]
                : undefined;
              const sv = Array.isArray(svTM) ? svTM[ii] : svTM;
              return (
                <React.Fragment key={ii}>
                  <sd.CollectionMetaInformation grid={grid}>
                    <p>
                      {collection.group.name}
                      {collection.group.year
                        ? `, ${collection.group.year}`
                        : ""}
                    </p>
                    <p>{collection.group.description ? `${collection.group.description} cm` : ""}</p>
                    <p>{collection.group.technique}</p>
                    <p>
                      {collection.group.workNo
                        ? `Werk Nr. ${collection.group.workNo}`
                        : ""}
                    </p>
                  </sd.CollectionMetaInformation>
                  <sd.Image
                    alt={image}
                    data-image-name={image}
                    grid={grid}
                    onClick={imageClickHandler}
                    onContextMenu={imageContextMenuHandler}
                    scaleHorizontal={sh}
                    scaleVertical={sv}
                    src={getGalleryImagePath(image, imageSubPath)}
                    title={collection.group.name}
                  />
                </React.Fragment>
              );
            })}
          </sd.Grid>
        );
      })}
    </>
  );
};

export default CollectionRendererGrid;
