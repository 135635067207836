//* third party packages
import React from "react";
import { useKeyPressEvent } from "react-use";
import { useNavigate } from "react-router-dom";

//* components
import * as menu from "components/Apps/Main/Components/MenuOverlay.styled";
import * as s from "components/Apps/Main/Navbar/FilterMenu.styled";
// import FooterTablet from "components/Apps/Main/Components/Footer/FooterTablet";
import MainNavigationLinks from "components/Apps/Main/Navbar/MainNavigationLinks";

//* other
import routes from "constants/routes";
import { Context } from "components/Apps/Main/Context";
import { ReactComponent as FilterIcon } from "assets/icons/filterIcon.svg";
// import { ReactComponent as FrackmannLogo } from "assets/Frackmann_logo_off-black.svg";
import { ReactComponent as X } from "assets/icons/X.svg";

const FilterMenu: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const { breakPoints, filterOpen, toggleFilterOpen, setFilterOpen } =
    React.useContext(Context);

  useKeyPressEvent("Escape", () => setFilterOpen(false));

  if (breakPoints.isDesktop) {
    return null;
  }

  return (
    <menu.MenuContainer open={filterOpen} className={"filterMenu"}>
      <menu.LogoContainer
        title={"Harald Frackmann"}
        onClick={() => navigate(routes.home)}
      >
        Harald
        <br />
        Frackmann
        {/*<FrackmannLogo*/}
        {/*  title={"Frackmann"}*/}
        {/*  onClick={() => navigate(routes.home)}*/}
        {/*/>*/}
      </menu.LogoContainer>
      <s.FilterText>Sortieren nach</s.FilterText>
      <menu.CloseContainer title={"Sortieren schließen"}>
        <X onClick={toggleFilterOpen} />
      </menu.CloseContainer>
      <s.LinkContainer>
        <s.FilterContainer>
          <FilterIcon />
        </s.FilterContainer>
        <MainNavigationLinks />
      </s.LinkContainer>
      {/*<FooterTablet />*/}
    </menu.MenuContainer>
  );
};

export default FilterMenu;
