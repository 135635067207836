import selectedWorks from "assets/gallery/selected-works";
import work19701980 from "assets/gallery/work19701980";
import work19801990 from "assets/gallery/work19801990";
import work19902000 from "assets/gallery/work19902000";
import work20002020 from "assets/gallery/work20002020";

//* some local typings and constants
//*
//*
export type TGalleryImage = {
  name: string;
  src: string;
};

export type TGalleryGroup = {
  name: string;
  description: string;
  technique?: string;
  workNo?: string;
  year?: string;
};

export interface ICollectionGrid {
  cols: number;
  rows: number;
}

export type TScale = number | Array<number | undefined>;

export type TCollection = {
  gridHorizontal: ICollectionGrid;
  gridVerticalTablet: ICollectionGrid;
  gridVerticalMobile: ICollectionGrid;
  group: TGalleryGroup;
  images: string[];
  imagesSubPath?: string[];
  imagesDetailClass?: string | string[];
  scaleHorizontal?: TScale;
  scaleVerticalTablet?: TScale;
  scaleVerticalMobile?: TScale;
};

export interface ICollectionByFilter {
  meta: { className?: string };
  collection: TCollection[];
  flatCollection: TFlatCollectionResult;
}

export interface ICollectionsByFilter {
  selectedWorks: ICollectionByFilter;
  gallery19701980: ICollectionByFilter;
  gallery19801990: ICollectionByFilter;
  gallery19902000: ICollectionByFilter;
  gallery20002020: ICollectionByFilter;
}

export type TFlatCollection = {
  image: string;
  imageSubPath?: string;
  imageDetailClass?: string;
  group: TGalleryGroup;
  scaleHorizontal?: TScale;
  scaleVerticalTablet?: TScale;
  scaleVerticalMobile?: TScale;
};

export type TIndexToNameLookupTable = { [key: number]: string };
export type TNameToIndexLookupTable = { [key: string]: number };

export type TFlatCollectionResult = [
  TFlatCollection[],
  TIndexToNameLookupTable,
  TNameToIndexLookupTable
];

export const fullSizeImagePath = "/gallery/fullsize";
export const smallSizeImagePath = "/gallery/small";

/**
 * helper function
 * @param collections
 */
const flattenCollectionsAndBuildIndex = (
  collections: TCollection[]
): TFlatCollectionResult => {
  const flatCollections: TFlatCollection[] = [];
  const indexToNameLookupTable: TIndexToNameLookupTable = {};
  const nameToIndexLookupTable: TNameToIndexLookupTable = {};
  let currentIndex = 0;
  collections.forEach((collection, i) => {
    const {
      images,
      imagesSubPath,
      imagesDetailClass,
      group,
      scaleHorizontal,
      scaleVerticalTablet,
      scaleVerticalMobile,
    } = collection;
    images.forEach((image, ii) => {
      const imageDetailClass = Array.isArray(imagesDetailClass)
        ? imagesDetailClass[ii]
        : imagesDetailClass;
      const imageSubPath = Array.isArray(imagesSubPath)
        ? imagesSubPath[ii]
        : undefined;
      flatCollections.push({
        group: group,
        image,
        imageSubPath,
        imageDetailClass,
        scaleHorizontal,
        scaleVerticalTablet,
        scaleVerticalMobile,
      });
      indexToNameLookupTable[currentIndex] = image;
      nameToIndexLookupTable[image] = currentIndex;
      currentIndex++;
    });
  });
  return [flatCollections, indexToNameLookupTable, nameToIndexLookupTable];
};

export const CollectionsByFilter: ICollectionsByFilter = {
  selectedWorks: {
    meta: {
      className: "kuratiert",
    },
    collection: selectedWorks,
    flatCollection: [[], {}, {}],
  },
  gallery19701980: {
    meta: {
      className: "kuratiert",
    },
    collection: work19701980,
    flatCollection: [[], {}, {}],
  },
  gallery19801990: {
    meta: {
      className: "kuratiert",
    },
    collection: work19801990,
    flatCollection: [[], {}, {}],
  },
  gallery19902000: {
    meta: {
      className: "kuratiert",
    },
    collection: work19902000,
    flatCollection: [[], {}, {}],
  },
  gallery20002020: {
    meta: {
      className: "kuratiert",
    },
    collection: work20002020,
    flatCollection: [[], {}, {}],
  },
};

//* flatten the collections for image detail view
CollectionsByFilter.selectedWorks.flatCollection =
  flattenCollectionsAndBuildIndex(CollectionsByFilter.selectedWorks.collection);
CollectionsByFilter.gallery19701980.flatCollection =
  flattenCollectionsAndBuildIndex(
    CollectionsByFilter.gallery19701980.collection
  );
CollectionsByFilter.gallery19801990.flatCollection =
  flattenCollectionsAndBuildIndex(
    CollectionsByFilter.gallery19801990.collection
  );
CollectionsByFilter.gallery19902000.flatCollection =
  flattenCollectionsAndBuildIndex(
    CollectionsByFilter.gallery19902000.collection
  );
CollectionsByFilter.gallery20002020.flatCollection =
  flattenCollectionsAndBuildIndex(
    CollectionsByFilter.gallery20002020.collection
  );
