const work19701980 = [
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Vom Ideal des Schwarzen",
      description: "190 x 175",
      technique: "Öl auf Papier",
      workNo: "15",
      year: "1979/80",
    },
    images: ["15_Cut_DSCF0022.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Boote",
      description: "290 x 215",
      technique: "Öl auf Leinwand",
      workNo: "16",
      year: "1979/80",
    },
    images: ["16_Cut_DSCF0023.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 70,
    scaleVerticalTablet: 75,
    scaleVerticalMobile: 80,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 98",
      technique: "Öl auf Holz",
      workNo: "29",
      year: "1979/80",
    },
    images: ["29_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Flügelgewächs",
      description: "200 x 98",
      technique: "Öl auf Holz",
      workNo: "30",
      year: "1978/79",
    },
    images: ["30_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Wagner",
      description: "200 x 98",
      technique: "Öl auf Holz",
      workNo: "31",
      year: "",
    },
    images: ["31_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Zitat (?)",
      description: "190 x 99",
      technique: "Öl auf Holz",
      workNo: "32",
      year: "1977",
    },
    images: ["32_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Inszenierung II",
      description: "200 x 98",
      technique: "Öl auf Holz",
      workNo: "33",
      year: "1976/77",
    },
    images: ["33_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Kleiner Rhizomrest 2/4",
      description: "200 x 98",
      technique: "Öl auf Holz",
      workNo: "36",
      year: "1977/78/88",
    },
    images: ["36_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Kleiner Rhizomrest 3/4",
      description: "200 x 98",
      technique: "Öl auf Holz",
      workNo: "37",
      year: "1977/78/88",
    },
    images: ["37_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Dunkle Maske",
      description: "200 x 98",
      technique: "Öl auf Holz",
      workNo: "38",
      year: "1978/79",
    },
    images: ["38_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Kleiner Rhizomrest 4/4",
      description: "200 x 98",
      technique: "Öl auf Holz",
      workNo: "39",
      year: "1977/78/88",
    },
    images: ["39_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 135",
      technique: "Öl auf Holz",
      workNo: "43",
      year: "1978/79",
    },
    images: ["43_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Irgendwo & ungesichert",
      description: "200 x 135",
      technique: "Öl auf Holz",
      workNo: "46",
      year: "1978/85",
    },
    images: ["46_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 150",
      technique: "Öl auf Holz",
      workNo: "47",
      year: "1978/85",
    },
    images: ["47_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Bomb",
      description: "200 x 150",
      technique: "Öl auf Holz",
      workNo: "49",
      year: "1978/79",
    },
    images: ["49_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 150",
      technique: "Öl auf Holz",
      workNo: "50",
      year: "1978/85",
    },
    images: ["50_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Opium Fries",
      description: "200 x 75",
      technique: "Öl auf Holz",
      workNo: "121",
      year: "1970/85",
    },
    images: ["121_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "122 x 175",
      technique: "Öl auf Hartfaser",
      workNo: "132",
      year: "1979/80",
    },
    images: ["132_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "141 x 122",
      technique: "Öl auf Hartfaser",
      workNo: "143",
      year: "1979",
    },
    images: ["143_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Vom Ideal des Schwarzen",
      description: "175 x 180",
      technique: "Öl auf Karton",
      workNo: "263",
      year: "1979/80",
    },
    images: ["263_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "124 x 84",
      technique: "Öl auf Hartfaser",
      workNo: "270",
      year: "1979",
    },
    images: ["270_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "175 x 123",
      technique: "Öl auf Hartfaser",
      workNo: "271",
      year: "1978/81",
    },
    images: ["271_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Radlauf",
      description: "175 x 123",
      technique: "Öl auf Hartfaser",
      workNo: "272",
      year: "1978/79",
    },
    images: ["272_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "148 x 100",
      technique: "Öl auf Molton",
      workNo: "278",
      year: "1975",
    },
    images: ["278_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "129 x 94",
      technique: "Öl auf Holz",
      workNo: "281",
      year: "1977",
    },
    images: ["281_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "140 x 100",
      technique: "Öl auf Holz",
      workNo: "310",
      year: "1979",
    },
    images: ["310_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 130",
      technique: "Öl auf Hartfaser",
      workNo: "314",
      year: "",
    },
    images: ["314_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "85 x 70",
      technique: "Öl auf Holz",
      workNo: "875",
      year: "1975",
    },
    images: ["875_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "120 x 98",
      technique: "Öl auf Karton",
      workNo: "876",
      year: "",
    },
    images: ["876_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "104 x 74",
      technique: "Öl auf Holz",
      workNo: "884",
      year: "1974",
    },
    images: ["884_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
];

//* add selected works items
work19701980.push(
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Ölkreide auf Papier",
      workNo: "",
      year: "1975",
    },
    images: ["30x30_Ölkreide auf Papier_75.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Ölkreide auf Papier",
      workNo: "",
      year: "1979",
    },
    images: ["30x30_Ölkreide auf Papier_79.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Ölkreide auf Papier",
      workNo: "",
      year: "1979",
    },
    images: ["30x30_Ölkreide auf Papier_79_.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "44 x 62",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1979",
    },
    images: ["44x62_Öl auf Papier_79.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  }
);

export default work19701980;
