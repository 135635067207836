//* third party packages
import React from "react";

//* components
import * as s from "components/Apps/Main/Gallery/GroupInformation.styled";

//* other
import { Context } from "components/Apps/Main/Context";

const GroupInformation: React.FunctionComponent<TReactPropsWithChildren> = (
  props
) => {
  const { currentGroup } = React.useContext(Context);
  return <s.Container>{currentGroup}</s.Container>;
};

export default GroupInformation;
