//* third party packages
import React from "react";

//* components
import * as s from "components/Apps/Main/Components/Footer/Footer.styled";
// import Icons from "components/Apps/Main/Components/Footer/Icons";

//* other

const FooterTablet: React.FunctionComponent = () => {
  return (
    <s.FooterTabletContainer>
      {/*<Icons />*/}
      <s.Copyright>&copy; Harald Frackmann</s.Copyright>
    </s.FooterTabletContainer>
  );
};

export default FooterTablet;
