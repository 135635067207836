//* third party packages
import React from "react";

//* components
import * as s from "components/Apps/Main/Components/Footer/Footer.styled";

//* other

const Footer: React.FunctionComponent<TReactPropsWithChildren> = (props) => {
  return <s.FooterContainer>&copy; Harald Frackmann</s.FooterContainer>;
};

export default Footer;
