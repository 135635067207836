//* third party packages
import "@egjs/flicking-plugins/dist/arrow.css";
import "@egjs/react-flicking/dist/flicking.css";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import React from "react";
import { Arrow, Perspective } from "@egjs/flicking-plugins";

//* components
import * as sc from "components/Apps/Main/Gallery/CollectionRenderer.styled";
import { BodyBackgroundWall } from "components/Apps/Main/styles";

//* other
import imageContextMenuHandler from "components/Apps/Main/Helper/imageContextMenuHandler";
import useGetGalleryImagePath from "components/Apps/Main/Helper/useGetGalleryImagePath";
import { Context } from "components/Apps/Main/Context";
import { TFlatCollectionResult } from "assets/gallery/database-v2";
import CollectionRendererScrollbar from "components/Apps/Main/Gallery/CollectionRendererScrollbar";

//* local types
type TProps = {
  flatCollectionByFilter: TFlatCollectionResult;
  updateGroupInformation: boolean;
};

const CollectionRenderer: React.FunctionComponent<TProps> = ({
  flatCollectionByFilter,
}) => {
  const { setImageDetailId, flickingRef, imageDetailId } =
    React.useContext(Context);

  const getGalleryImagePath = useGetGalleryImagePath();

  const imageClickHandler = React.useCallback(
    (e: React.MouseEvent<HTMLImageElement>) => {
      setImageDetailId(e.currentTarget.dataset.imageName ?? null);
    },
    [setImageDetailId]
  );

  const wheelHandler = React.useCallback(
    (e: React.WheelEvent) => {
      if (flickingRef.current) {
        if (e.deltaY < 0) {
          flickingRef.current.prev().catch(() => {});
        } else {
          flickingRef.current.next().catch(() => {});
        }
      }
    },
    [flickingRef]
  );

  const [isMouseDown, setIsMouseDown] = React.useState(false);
  const [currentImageIndex, setCurrentImageIndex] = React.useState<number>(0);

  const plugins = React.useMemo(
    () => [new Perspective({ rotate: 0, scale: 3.0 }), new Arrow({})],
    []
  );
  return (
    <>
      {!imageDetailId && <BodyBackgroundWall />}
      <sc.CollectionRendererContainer
        isMouseDown={isMouseDown}
        onMouseDown={() => setIsMouseDown(true)}
        onMouseUp={() => setIsMouseDown(false)}
        onWheel={wheelHandler}
      >
        <Flicking
          circular={false}
          defaultIndex={0}
          hideBeforeInit={true}
          moveType={"strict"}
          noPanelStyleOverride={true}
          onChanged={(e) => setCurrentImageIndex(e.currentTarget.index)}
          plugins={plugins}
          ref={flickingRef}
        >
          {flatCollectionByFilter[0].map((item, i) => {
            const scaleHorizontal = Array.isArray(item.scaleHorizontal)
              ? item.scaleHorizontal[i]
              : item.scaleHorizontal;
            return (
              <sc.ImageContainer key={i}>
                <div>
                  <sc.Image
                    alt={item.image}
                    data-image-name={item.image}
                    draggable={false}
                    key={i}
                    onClick={imageClickHandler}
                    onContextMenu={imageContextMenuHandler}
                    scaleHorizontal={scaleHorizontal}
                    src={getGalleryImagePath(item.image, item.imageSubPath)}
                  />
                  {currentImageIndex === i && (
                    <sc.ImageText>
                      <p>
                        {item.group.name}
                        {item.group.year ? `, ${item.group.year}` : ""}
                      </p>
                      <p>{item.group.description ? `${item.group.description} cm` : ""}</p>
                      <p>{item.group.technique}</p>
                      <p>
                        {item.group.workNo
                          ? `Werk Nr. ${item.group.workNo}`
                          : ""}
                      </p>
                    </sc.ImageText>
                  )}
                </div>
              </sc.ImageContainer>
            );
          })}
          <ViewportSlot>
            <span className="flicking-arrow-prev is-thin"></span>
            <span className="flicking-arrow-next is-thin"></span>
          </ViewportSlot>
        </Flicking>
        <CollectionRendererScrollbar total={flatCollectionByFilter[0].length} />
      </sc.CollectionRendererContainer>
    </>
  );
};

export default CollectionRenderer;
