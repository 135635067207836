const routes = {
  home: "/",

  gallery: "/werkauswahl",
  gallery19701980: "/werkauswahl/1970-1980",
  gallery19801990: "/werkauswahl/1980-1990",
  gallery19902000: "/werkauswahl/1990-2000",
  gallery20002020: "/werkauswahl/2000-2020",

  biografie: "/biografie",
  kontakt: "/kontakt",
  literatur: "/literatur",
  impressum: "/impressum",
  datenschutz: "/datenschutz",
};

export default routes;
