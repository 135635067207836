//* third party packages
import React from "react";

//* components
import * as s from "components/Apps/Main/Navbar/Navbar.styled";
// import Filter from "components/Apps/Main/Navbar/Filter";
import MainNavigationLinks from "components/Apps/Main/Navbar/MainNavigationLinks";

const MainNavigation: React.FunctionComponent = () => {
  return (
    <s.MainNavContainer>
      {/*<Filter />*/}
      <MainNavigationLinks />
    </s.MainNavContainer>
  );
};

export default MainNavigation;
