//* third party packages
// import Magnifier from "react-magnifier";
import React from "react";
import { Slide, SlideshowRef } from "react-slideshow-image";
import { useKeyPressEvent } from "react-use";
import { useMatch, useNavigate } from "react-router-dom";

//* components
import * as s from "components/Apps/Main/Components/ImageDetail/ImageDetail.styled";
import {
  LogoContainer,
  CloseContainer,
} from "components/Apps/Main/Components/MenuOverlay.styled";

//* other
import * as db from "assets/gallery/database-v2";
import imageContextMenuHandler from "components/Apps/Main/Helper/imageContextMenuHandler";
import routes from "constants/routes";
import scrollIntoView from "components/Apps/Main/Helper/scrollIntoView";
import useGetGalleryImagePath from "components/Apps/Main/Helper/useGetGalleryImagePath";
import { CollectionMetaInformation } from "components/Apps/Main/Gallery/CollectionRendererGrid.styled";
import { Context } from "components/Apps/Main/Context";
import { ReactComponent as BackArrow } from "assets/icons/back_arrow.svg";
import { ReactComponent as FrackmannLogo } from "assets/Frackmann_logo_off-black.svg";
import { ReactComponent as More } from "assets/icons/more_zyklus.svg";
import { ReactComponent as Swipe } from "assets/icons/swipe.svg";
import { ReactComponent as X } from "assets/icons/X.svg";

/**
 *
 * @constructor
 */
export const ImageDetail: React.FunctionComponent = () => {
  const {
    imageDetailId,
    setImageDetailId,
    breakPoints,
    setScrollToGroupName,
    flickingRef,
  } = React.useContext(Context);

  const navigate = useNavigate();

  useKeyPressEvent("Escape", () => setImageDetailId(null));
  const getGalleryImagePath = useGetGalleryImagePath();

  const isKuratiert = useMatch(routes.gallery19701980);
  const isZyklen = useMatch(routes.gallery19801990);
  const isMalereiCollage = useMatch(routes.gallery19902000);
  const isGemaelde = useMatch(routes.gallery20002020);
  const isWerkauswahl = useMatch(routes.home);

  const [
    flatCollections,
    indexToNameLookupTable,
    nameToIndexLookupTable,
    displayMoreOfZyklus,
    pathname,
  ] = React.useMemo(() => {
    let fc: db.TFlatCollection[] = [];
    let i2n: db.TIndexToNameLookupTable = {};
    let n2i: db.TNameToIndexLookupTable = {};
    let more = false;
    let pn = "";
    switch (true) {
      case !!isKuratiert:
        [fc, i2n, n2i] = db.CollectionsByFilter.gallery19701980.flatCollection;
        // more = true;
        pn = isKuratiert?.pathname ?? "";
        break;
      case !!isZyklen:
        [fc, i2n, n2i] = db.CollectionsByFilter.gallery19801990.flatCollection;
        pn = isZyklen?.pathname ?? "";
        break;
      case !!isMalereiCollage:
        [fc, i2n, n2i] = db.CollectionsByFilter.gallery19902000.flatCollection;
        pn = isMalereiCollage?.pathname ?? "";
        break;
      case !!isGemaelde:
        [fc, i2n, n2i] = db.CollectionsByFilter.gallery20002020.flatCollection;
        pn = isGemaelde?.pathname ?? "";
        break;
      case !!isWerkauswahl:
        [fc, i2n, n2i] = db.CollectionsByFilter.selectedWorks.flatCollection;
        pn = isGemaelde?.pathname ?? "";
        break;
    }
    return [fc, i2n, n2i, more, pn];
  }, [isKuratiert, isZyklen, isMalereiCollage, isGemaelde, isWerkauswahl]);

  const slideShowRef = React.useRef<SlideshowRef>(null);

  const [currentImageIndex, setCurrentImageIndex] = React.useState(
    nameToIndexLookupTable[imageDetailId ?? 0]
  );

  const [displaySwipeHint, setDisplaySwipeHint] = React.useState(false);

  const [visibility, setVisibility] = React.useState(false);
  React.useLayoutEffect(() => {
    if (imageDetailId !== null && slideShowRef.current) {
      const to = nameToIndexLookupTable[imageDetailId];
      setVisibility(true);
      setCurrentImageIndex(to);
      slideShowRef.current.goTo(to, { skipTransition: true });
      if (flickingRef.current) {
        flickingRef.current.moveTo(to);
      }
    }
  }, [
    imageDetailId,
    setVisibility,
    slideShowRef,
    nameToIndexLookupTable,
    flickingRef,
  ]);

  if (flatCollections.length === 0) {
    return null;
  }

  return (
    <s.ImageDetailOverlay
      $visible={imageDetailId !== null}
      $visibility={visibility}
      onClick={() => {
        setImageDetailId(null);
      }}
      key={pathname}
    >
      <LogoContainer>
        <FrackmannLogo />
      </LogoContainer>
      <CloseContainer style={{ zIndex: 4 }}>
        <X onClick={() => setImageDetailId(null)} />
      </CloseContainer>
      <Slide
        ref={slideShowRef}
        transitionDuration={500}
        autoplay={false}
        infinite={false}
        canSwipe={false}
        // prevArrow={!breakPoints.isMobile ? <LeftArrow /> : <></>}
        // nextArrow={!breakPoints.isMobile ? <RightArrow /> : <></>}
        prevArrow={<></>}
        nextArrow={<></>}
        onChange={(from, to) => {
          if (flickingRef.current) {
            flickingRef.current.moveTo(to);
          } else {
            scrollIntoView(
              `img[data-image-name="${indexToNameLookupTable[to]}"]`,
              breakPoints.isDesktop,
              true
            );
          }
          setCurrentImageIndex(to);
          setDisplaySwipeHint(false);
        }}
      >
        {flatCollections.map((item, i) => (
          <s.Container key={i}>
            <s.Wrapper>
              {displayMoreOfZyklus && (
                <s.MoreOfZyklusContainer
                  className={item.imageDetailClass ?? undefined}
                  onClick={() => {
                    navigate(routes.gallery19801990);
                    setScrollToGroupName(item.group.name);
                    setImageDetailId(null);
                  }}
                  title={"Mehr aus diesem Zyklus"}
                  $visible={currentImageIndex === i}
                >
                  <div>
                    <More className={"inverted-light-dark"} />
                  </div>
                  <div>Mehr aus diesem Zyklus</div>
                </s.MoreOfZyklusContainer>
              )}
              <img
                alt={""}
                src={getGalleryImagePath(item.image, item.imageSubPath)}
                draggable={false}
                onContextMenu={imageContextMenuHandler}
              />
              {/*<Magnifier*/}
              {/*  mgBorderWidth={0}*/}
              {/*  mgHeight={250}*/}
              {/*  mgWidth={250}*/}
              {/*  src={getGalleryImagePath(item.image, item.imageSubPath)}*/}
              {/*  zoomFactor={1.2}*/}
              {/*  ref={(m) => {*/}
              {/*    m?.img.addEventListener(*/}
              {/*      "contextmenu",*/}
              {/*      imageContextMenuHandler*/}
              {/*    );*/}
              {/*    m?.img.addEventListener("click", (e) => {*/}
              {/*      e.stopPropagation();*/}
              {/*      e.preventDefault();*/}
              {/*    });*/}
              {/*    if (m?.img) {*/}
              {/*      m.img.draggable = false;*/}
              {/*    }*/}
              {/*  }}*/}
              {/*/>*/}
              <CollectionMetaInformation
                grid={undefined}
                className={"right"}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <p>
                  {item.group.name}
                  {item.group.year ? `, ${item.group.year}` : ""}
                </p>
                <p>
                  {item.group.description ? `${item.group.description} cm` : ""}
                </p>
                <p>{item.group.technique}</p>
                <p>
                  {item.group.workNo ? `Werk Nr. ${item.group.workNo}` : ""}
                </p>
              </CollectionMetaInformation>
            </s.Wrapper>
          </s.Container>
        ))}
      </Slide>
      <s.BackToGalleryContainer
        onClick={() => setImageDetailId(null)}
        title={"Zurück zur Werkauswahl"}
      >
        <BackArrow />
      </s.BackToGalleryContainer>
      {breakPoints.isMobile && (
        <s.SwipeHintContainer $remove={!displaySwipeHint}>
          <Swipe />
          <div>Swipe Rechts /Links</div>
        </s.SwipeHintContainer>
      )}
      <s.FooterImageDetail>&copy; Harald Frackmann</s.FooterImageDetail>
    </s.ImageDetailOverlay>
  );
};

export default ImageDetail;
