import styled, { createGlobalStyle, css } from "styled-components/macro";

import OpenSansBold from "assets/fonts/OpenSans-Bold.ttf";
import OpenSansRegular from "assets/fonts/OpenSans-Regular.ttf";

import "simplebar-react/dist/simplebar.min.css";
import "react-slideshow-image/dist/styles.css";
import BackgroundImage from "assets/images/exhibition_wall/background.jpg";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-display: block;
    font-family: "Open-Sans";
    font-style: normal;
    font-weight: 300;
    src: local("Open-Sans"),
    url(${OpenSansRegular}) format("truetype");
  }
  @font-face {
    font-display: block;
    font-family: "Open-Sans Bold";
    font-style: normal;
    font-weight: 700;
    src: local("Open-Sans Bold"),
    url(${OpenSansBold}) format("truetype");
  }
  :root {
    --doc-height: 100%;
    --mobile-max-width: 575px;
    --tablet-max-width: 991px;
    --desktop-min-width: 992px;

    /* TODO: use these values in respective components */
    --navbar-height: 100px;
    --footer-height: 28px;
    --xtra-bottom-margin: 0px;

    --text-page-margin-top: 95px;
    --text-page-margin-right: 160px;
    --text-page-margin-bottom: 95px;
    --text-page-margin-left: 160px;
    --text-page-headline-height: 60px;

    --text-page-content-container-height: calc(
            var(--doc-height) - var(--navbar-height) - var(--footer-height) -
            var(--text-page-margin-top) - var(--text-page-margin-bottom)
    );

    @media (max-height: 850px) {
      --text-page-margin-top: 20px;
      --text-page-margin-bottom: 20px;
    }

    @media (max-width: 991px) {
      --navbar-height: 70px;
      --text-page-margin-top: 28px;
      --text-page-margin-right: 22px;
      --text-page-margin-bottom: 120px;
      --text-page-margin-left: 22px;
      --text-page-headline-height: 60px;
      --xtra-bottom-margin: 40px;
    }

    @media (max-width: 575px) {
      --navbar-height: 70px;
      --text-page-margin-top: 28px;
      --text-page-margin-right: 18px;
      --text-page-margin-bottom: 28px;
      --text-page-margin-left: 18px;
      --text-page-headline-height: 30px;
    }

    --exhibition-wall-container-height: calc(var(--doc-height) - var(--navbar-height) - var(--footer-height) - var(--xtra-bottom-margin));
    --exhibition-wall-container-width: calc(100vw - 106px);
    @media (max-width: 991px) {
      --exhibition-wall-container-width: calc(100vw - 44px);
    }
  }
  body {
    background-color: ${({ theme }) => theme.color.elements.mainBackground};
    color: ${({ theme }) => theme.color.elements.textColor};
    font-family: "Open-Sans", serif;
    overflow: hidden;
  }
  a {
    color: ${({ theme }) => theme.color.elements.textColor};
  }

  :focus-visible {
    outline: none;
  }

  section#frackmann {
    position: relative;
    overflow: hidden;
  }

  svg:not([class=homepage-svg]) *[stroke] {
    stroke: ${({ theme }) => theme.color.elements.textColor} !important;
  }
  svg:not([class=homepage-svg]) *[fill] {
    fill: ${({ theme }) => theme.color.elements.textColor} !important;
  }

  svg[class=inverted-light-dark] circle[fill] {
    fill: ${({ theme }) => theme.color.elements.textColor} !important;
  }

  svg[class=inverted-light-dark] *[stroke] {
    stroke: ${({ theme }) => theme.color.elements.mainBackground} !important;
  }
`;

export const BodyBackgroundWall = createGlobalStyle`
  body {
    background-image: url(${BackgroundImage});
    background-position: bottom;
    background-attachment: fixed;
    background-size: cover;
  }
`;

export const AppContainer = styled.div`
  width: 100vw;
  height: var(--doc-height);
`;

export const SVGHover = css`
  &:hover svg {
    cursor: pointer;
  }
  &:hover svg *[stroke-width] {
    stroke-width: 3;
  }
  &:hover svg *[stroke-width^="0"] {
    stroke-width: 1;
  }
  &:hover svg *[stroke-width^="1"] {
    stroke-width: 2;
  }
  &:hover svg *[stroke-width^="2"] {
    stroke-width: 2.5;
  }
  &:hover svg *[stroke-width^="3"] {
    stroke-width: 3.4;
  }
`;

export const SVGDirectHover = css`
  & svg:hover *[stroke-width^="0"] {
    stroke-width: 1;
  }
  & svg:hover *[stroke-width^="1"] {
    stroke-width: 2;
  }
  & svg:hover *[stroke-width^="2"] {
    stroke-width: 2.5;
  }
  & svg:hover *[stroke-width^="3"] {
    stroke-width: 3.4;
  }
  & svg:hover {
    cursor: pointer;
  }
`;
