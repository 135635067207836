import SimpleBar from "simplebar-react";
import styled from "styled-components/macro";

export const ExhibitionWallContainer = styled(SimpleBar)<{
  $showTrack?: boolean;
}>`
  height: var(--exhibition-wall-container-height);
  overflow: initial;
  width: var(--exhibition-wall-container-width);
  margin: 0 53px;
  border: none;
  display: flex;
  flex-flow: column;
  @media (max-width: 991px) {
    margin: 0 22px;
  }
  .simplebar-track.simplebar-horizontal {
    height: 8px;
  }

  .simplebar-track.simplebar-horizontal.simplebar-hover {
    height: 12px;
  }
  .simplebar-track {
    border-radius: 10px;
    background-color: ${({ theme, $showTrack }) =>
      $showTrack ? theme.color.palette.grey : "transparent"};
  }
`;

export const Wall = styled.div`
  height: var(--exhibition-wall-container-height);
  display: flex;
  @media (max-width: 991px) {
    height: initial;
    margin-top: 60px;
    flex-direction: column;
  }
`;
