import React from "react";
import { Context } from "components/Apps/Main/Context";
import {
  fullSizeImagePath,
  smallSizeImagePath,
} from "assets/gallery/database-v2";

const useGetGalleryImagePath = () => {
  const { breakPoints } = React.useContext(Context);
  return React.useCallback(
    (image: string, imageSubPath: string | undefined) => {
      if (breakPoints.isDesktop || breakPoints.isTablet) {
        // prettier-ignore
        return `${fullSizeImagePath}${imageSubPath ? "/" + imageSubPath : ""}/${image}`;
      }
      // prettier-ignore
      return `${smallSizeImagePath}${imageSubPath ? "/" + imageSubPath : ""}/${image}`;
    },
    [breakPoints]
  );
};

export default useGetGalleryImagePath;
