import styled from "styled-components/macro";

export const CollectionRendererContainer = styled.div<{ isMouseDown: boolean }>`
  align-items: center;
  cursor: ${({ isMouseDown }) => (isMouseDown ? "grabbing" : "grab")};
  display: flex;
  height: calc(var(--doc-height) - var(--navbar-height) - var(--footer-height));
  justify-content: center;
  position: relative;
  width: 100vw;
  & .flicking-arrow-prev::before,
  & .flicking-arrow-prev::after,
  & .flicking-arrow-next::before,
  & .flicking-arrow-next::after,
  & .flicking-pagination-bullet-active {
    background-color: ${({ theme }) => theme.color.elements.textColor};
  }
  & .flicking-arrow-prev,
  & .flicking-arrow-next,
  & .flicking-pagination {
    z-index: 1;
  }
  & .flicking-arrow-disabled {
    display: none;
  }
  & .flicking-pagination {
    font-size: 12px;
  }
`;

export const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  height: calc(var(--doc-height) - var(--navbar-height) - var(--footer-height));
  justify-content: center;
  width: 950px;
  transform: translateZ(0);
  @media (max-width: 1700px) {
    width: 600px;
  }
  @media (max-width: 1000px) {
    width: 700px;
  }
  @media (max-width: 900px) {
    width: 600px;
  }
  @media (max-width: 800px) {
    width: 500px;
  }
  position: relative;
  & > div:first-child {
    position: relative;
  }
`;

export const Image = styled.img<{
  scaleHorizontal?: number;
  scaleVertical?: number;
  isNext?: boolean;
  isPrev?: boolean;
}>`
  position: relative;
  cursor: pointer;
  //filter: drop-shadow(0 0 4px #a8a5a5);
  @media (max-width: 1200px) {
    max-width: calc(
      800px / 1 / 100 * ${({ scaleHorizontal }) => scaleHorizontal ?? 50}
    );
  }
  @media (max-width: 1000px) {
    max-width: calc(
      700px / 1 / 100 * ${({ scaleHorizontal }) => scaleHorizontal ?? 50}
    );
  }
  @media (max-width: 900px) {
    max-width: calc(
      600px / 1 / 100 * ${({ scaleHorizontal }) => scaleHorizontal ?? 50}
    );
  }
  @media (max-width: 800px) {
    max-width: calc(
      500px / 1 / 100 * ${({ scaleHorizontal }) => scaleHorizontal ?? 50}
    );
  }
  max-width: calc(
    900px / 1 / 100 * ${({ scaleHorizontal }) => scaleHorizontal ?? 50}
  );
  max-height: calc(var(--exhibition-wall-container-height) - 200px);
`;

export const ImageText = styled.div`
  bottom: 1px;
  font-family: "Open-Sans", serif;
  font-size: 12px;
  left: 100%;
  margin-left: 10px;
  position: absolute;
  width: 200px;
  & > p:first-child {
    font-family: "Open-Sans Bold", serif;
    font-size: 11px;
    line-height: 14.98px;
  }
  & > p:last-child {
    font-family: "Open-Sans", serif;
    font-size: 11px;
    line-height: 14.98px;
  }
  & > p {
    margin: 0;
  }
`;

export const ScrollbarWrapper = styled.div<{ hide: boolean }>`
  align-items: center;
  background-color: transparent;
  border-radius: 10px;
  bottom: 20px;
  cursor: default;
  display: flex;
  height: 16px;
  left: 20px;
  position: absolute;
  width: calc(100vw - 40px);
  z-index: 2;
  opacity: 0.5;
  visibility: ${({ hide }) => (hide ? "hidden" : "visible")};
`;

export const ScrollbarHandle = styled.div`
  background-color: transparent;
  height: 40px;
  position: absolute;
  width: 60px;
  display: flex;
  align-items: center;
`;

export const ScrollbarHandleInner = styled.div`
  width: 60px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
`;
