//* third party packages
import React from "react";

//* components
import * as s from "components/Apps/Main/Pages/Pages.styled";

//* other

const Impressum: React.FunctionComponent = () => {
  return (
    <s.TextPageContentContainer>
      <s.TextPageHeadline>Impressum</s.TextPageHeadline>
      <s.TextPageScrollContainer>
        {/* prettier-ignore */}
        <s.ImpressumParagraph>
          Der Inhalt dieser Website (Texte, Bilder, Fotografien) unterliegt dem
          Schutz des Urheberrechts und darf nicht zu kommerziellen Zwecken
          kopiert, verbreitet, verändert oder Dritten zugänglich gemacht werden.
          Für alle rechtswidrigen Verlinkungen und deren Inhalt wird keine
          Verantwortung übernommen.
        </s.ImpressumParagraph>
        {/* prettier-ignore */}
        <s.ImpressumParagraph>
          Verantwortlich für den Inhalt dieser Website ist Harald Frackmann.
          <br />
          22303 Hamburg
        </s.ImpressumParagraph>
      </s.TextPageScrollContainer>
    </s.TextPageContentContainer>
  );
};

export default Impressum;
