import React from "react";

const useRecursiveTimeout = (callback: () => void, delay: number | null) => {
  const savedCallback = React.useRef(callback);

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout loop.
  React.useEffect(() => {
    let id: NodeJS.Timeout;
    function tick() {
      savedCallback.current();
      if (delay !== null) {
        id = setTimeout(tick, delay);
      }
    }
    if (delay !== null) {
      id = setTimeout(tick, delay);
      return () => id && clearTimeout(id);
    }
  }, [delay]);
};

export default useRecursiveTimeout;
