//* third party packages
import React from "react";
import { useNavigate } from "react-router-dom";

//* components
import * as s from "components/Apps/Main/Pages/Pages.styled";
import { ReactComponent as BackArrowVertical } from "assets/icons/back_arrow_vertical.svg";

//* other
import routes from "constants/routes";

export const BackArrow: React.FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <s.BackArrowContainer onClick={() => navigate(routes.gallery19701980)}>
      <BackArrowVertical />
      <div>Werkauswahl</div>
    </s.BackArrowContainer>
  );
};

export default BackArrow;
