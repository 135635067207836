import styled from "styled-components/macro";

//* other
import { ICollectionGrid } from "assets/gallery/database-v2";

export const Grid = styled.div<{ grid: ICollectionGrid }>`
  align-content: center;
  display: grid;
  flex: 0;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: ${({ grid }) => `repeat(${grid.cols}, auto)`};
  grid-template-rows: ${({ grid }) => `repeat(${grid.rows}, auto)`};
  justify-content: center;
  max-height: inherit;

  &:not(:last-child) {
    margin-right: 460px;
    @media (max-width: 991px) {
      margin-right: initial;
      margin-bottom: 120px;
    }
  }

  @media (max-width: 991px) {
    &.right-left {
      &:nth-child(even) {
        justify-content: start;
        margin-left: 25px;
      }

      &:nth-child(odd) {
        justify-content: end;
        margin-right: 25px !important;
      }
    }

    &.left-right {
      &:nth-child(even) {
        justify-content: end;
        margin-right: 25px !important;
      }

      &:nth-child(odd) {
        justify-content: start;
        margin-left: 25px;
      }
    }
  }

  &.kuratiert {
    &:not(:last-child) {
      justify-content: center;
      align-content: center;
      margin-right: 230px;
      @media (max-width: 991px) {
        margin-right: initial;
        margin-bottom: 130px;
      }
    }
  }
`;

export const Image = styled.img<{
  grid: ICollectionGrid;
  scaleHorizontal?: number;
  scaleVertical?: number;
}>`
  cursor: pointer;
  align-self: center;
  max-height: calc(
    var(--exhibition-wall-container-height) / ${({ grid }) => grid.rows} / 100 *
      ${({ scaleHorizontal }) => scaleHorizontal ?? 50}
  );
  @media (max-width: 991px) {
    max-height: initial;
    justify-self: center;
    max-width: calc(
      var(--exhibition-wall-container-width) / ${({ grid }) => grid.cols} / 100 *
        ${({ scaleVertical }) => scaleVertical ?? 60}
    );

    .left-right:nth-child(even) & {
      justify-self: end;
    }
    .left-right:nth-child(odd) & {
      justify-self: start;
    }

    .right-left:nth-child(even) & {
      justify-self: start;
    }
    .right-left:nth-child(odd) & {
      justify-self: end;
    }
  }
`;

export const CollectionMetaInformation = styled.div<{
  grid?: ICollectionGrid;
}>`
  grid-column: ${({ grid }) => (grid ? `1 / ${grid.cols + 1}` : "initial")};
  grid-row: ${({ grid }) =>
    grid ? `${grid.rows + 1} / ${grid.rows + 2}` : "initial"};
  margin-top: 10px;
  & > p:first-child {
    font-family: "Open-Sans Bold", serif;
    font-size: 11px;
    line-height: 14.98px;
  }
  & > p:not(:first-child) {
    font-family: "Open-Sans", serif;
    font-size: 11px;
    line-height: 14.98px;
  }
  & > p {
    margin: 0;
  }
  @media (max-width: 991px) {
    grid-column: ${({ grid }) => (grid ? `1 / span ${grid.cols}` : "initial")};
    grid-row: 1 / 2;
    margin-bottom: 10px;
    margin-top: initial;
    white-space: break-spaces;
    & > p:first-child {
      font-family: "Open-Sans Bold", serif;
      font-size: 11px;
      line-height: 14.98px;
      margin: 0;
    }
    & > p:last-child {
      font-family: "Open-Sans", serif;
      font-size: 11px;
      line-height: 14.98px;
    }
  }

  .kuratiert & {
    @media (max-width: 991px) {
      grid-row: ${({ grid }) => (grid ? `-1` : "initial")};
    }
  }

  @media (min-width: 992px) {
    &.right {
      position: absolute;
      left: calc(100% + 10px);
      bottom: 0;
      width: 300px;
    }
  }
`;
