import styled from "styled-components/macro";
import { MainNavElement } from "components/Apps/Main/Navbar/Navbar.styled";
import { SVGDirectHover } from "components/Apps/Main/styles";

export const LinkContainer = styled.div`
  ${SVGDirectHover};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 47px;
  & > :not(:last-child) {
    margin-bottom: 19px;
  }
  @media (max-width: 991px) {
    margin-left: initial;
    justify-content: center;
    align-items: center;
    width: 100%;
    & > :not(:last-child) {
      margin-bottom: 30px;
      @media (max-width: 991px) {
      }
      @media (max-width: 575px) {
        margin-bottom: 19px;
      }
    }
  }
`;

export const TinyLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 991px) {
    justify-content: center;
    align-items: center;
    width: 100%;
    & > :not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

export const NavElement = styled(MainNavElement)<{ active: boolean }>`
  text-decoration: ${({ active }) => (active ? "underline" : "none")};
  text-underline-offset: 5px;
  font-size: 28px;
  line-height: 38.13px;
  text-align: left;
  @media (max-width: 1120px) and (min-width: 992px) {
    font-size: 28px !important;
  }
  @media (max-width: 991px) {
    display: block;
    font-size: 42px;
  }
  @media (max-width: 575px) {
    display: block;
    font-size: 28px;
    line-height: 38.13px;
  }
`;

export const TinyNavElement = styled(MainNavElement)<{ active: boolean }>`
  text-decoration: ${({ active }) => (active ? "underline" : "none")};
  text-underline-offset: 5px;
  font-size: 18px;
  line-height: 25.43px;
  text-align: left;
  @media (max-width: 991px) {
    display: block;
  }
  &:hover {
    font-family: "Open-Sans", serif;
    font-style: italic;
  }
`;
