//* third party packages
import React from "react";
import { useMatch, useNavigate } from "react-router-dom";

//* components
import * as s from "components/Apps/Main/Navbar/Navbar.styled";

//* other
import routes from "constants/routes";
import { Context } from "components/Apps/Main/Context";

const MainNavigationLinks: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { setFilterOpen, setImageDetailId } = React.useContext(Context);
  return (
    <>
      <s.MainNavElement
        active={!!useMatch(routes.gallery19701980)}
        title={"1970-1980"}
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
          navigate(routes.gallery19701980);
          setFilterOpen(false);
          setImageDetailId(null);
        }}
        href={routes.gallery19701980}
      >
        1970-1980
      </s.MainNavElement>
      <s.MainNavElement
        active={!!useMatch(routes.gallery19801990)}
        title={"1980-1990"}
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
          navigate(routes.gallery19801990);
          setFilterOpen(false);
          setImageDetailId(null);
        }}
        href={routes.gallery19801990}
      >
        1980-1990
      </s.MainNavElement>
      <s.MainNavElement
        active={!!useMatch(routes.gallery19902000)}
        title={"1990-2000"}
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
          navigate(routes.gallery19902000);
          setFilterOpen(false);
          setImageDetailId(null);
        }}
        href={routes.gallery19902000}
      >
        1990-2000
      </s.MainNavElement>
      <s.MainNavElement
        active={!!useMatch(routes.gallery20002020)}
        title={"2000-2020"}
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
          navigate(routes.gallery20002020);
          setFilterOpen(false);
          setImageDetailId(null);
        }}
        href={routes.gallery20002020}
      >
        2000-2020
      </s.MainNavElement>
    </>
  );
};

export default MainNavigationLinks;
