//* third party packages
import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

//* components
import * as s from "components/Apps/Main/Navbar/Navbar.styled";
import MainNavigation from "components/Apps/Main/Navbar/MainNavigation";

//* other
import routes from "constants/routes";
import { Context } from "components/Apps/Main/Context";
import { ReactComponent as BurgerMenu } from "assets/icons/BurgerMenu.svg";

const Navbar: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { toggleMenuOpen } = React.useContext(Context);
  return (
    <s.NavbarContainer>
      <s.NavbarContent>
        <s.LogoContainer
          title={"Harald Frackmann"}
          onClick={() => navigate(routes.home)}
        >
          Harald
          <br />
          Frackmann
        </s.LogoContainer>
        <Routes>
          <Route path={routes.home} element={<MainNavigation />} />
          <Route path={routes.gallery19701980} element={<MainNavigation />} />
          <Route path={routes.gallery19801990} element={<MainNavigation />} />
          <Route path={routes.gallery19902000} element={<MainNavigation />} />
          <Route path={routes.gallery20002020} element={<MainNavigation />} />
        </Routes>
        <s.ButtonsRightContainer>
          <BurgerMenu title={"Menu"} onClick={toggleMenuOpen} />
        </s.ButtonsRightContainer>
      </s.NavbarContent>
    </s.NavbarContainer>
  );
};

export default Navbar;
