//* third party packages
import React from "react";
import ReactDOM from "react-dom/client";
import log from "loglevel";

//* components
import AppLoader from "AppLoader";

//* other
import reportWebVitals from "reportWebVitals";

//* setup logging
if (window) {
  window.log = log;
  window.collectionRendererChangeByDraggable = false;
}

const root = ReactDOM.createRoot(
  document.getElementById("frackmann") as HTMLElement
);

root.render(
  <>
    <AppLoader />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
