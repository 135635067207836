//* third party packages
import React from "react";
import { ThemeProvider as SCThemeProvider } from "styled-components/macro";

//* other
import themeDark from "theme/dark";
import themeLight from "theme/light";
import { Context } from "components/Apps/Main/Context";

const ThemeProvider: React.FunctionComponent<TReactPropsWithChildren> = (
  props
) => {
  const { lightTheme } = React.useContext(Context);
  return (
    <SCThemeProvider theme={lightTheme ? themeLight : themeDark}>
      {props.children}
    </SCThemeProvider>
  );
};

export default ThemeProvider;
