//* third party packages
import React from "react";

//* components
import * as s from "components/Apps/Main/Pages/Pages.styled";

//* other

const Datenschutz: React.FunctionComponent = () => {
  return (
    <s.TextPageContentContainer>
      <s.TextPageHeadline>Datenschutzerklärung</s.TextPageHeadline>
      <s.TextPageScrollContainer>
        <s.TwoColWrapper>
          {/* prettier-ignore */}
          <s.TwoColParagraph>
              <b>Verantwortliche Stelle im Sinne der Datenschutzgesetze ist:</b><br />
              Harald Frackmann<br />
              Barmbecker Str. 7/9 a<br />
              22303 Hamburg<br />
              Tel. 040-2806057<br />
              E-Mail: hfrackmann@gmx.de<br />
              <br />
              <b>Erfassung allgemeiner Informationen</b><br />
              Wenn Sie auf unsere Webseite zugreifen, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet Service Providers und Ähnliches. Hierbei handelt es sich ausschließlich um Informationen, welche keine Rückschlüsse auf Ihre Person zulassen. Diese Informationen sind technisch notwendig, um von Ihnen angeforderte Inhalte von Webseiten korrekt auszuliefern und fallen bei Nutzung des Internets zwingend an. Anonyme Informationen dieser Art werden von uns statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.<br />
              <br />
              <b>Registrierung auf unserer Webseite</b><br />
              Bei der Registrierung für die Nutzung unserer personalisierten Leistungen werden einige personenbezogene Daten erhoben, wie Name, Anschrift, Kontakt- und Kommunikationsdaten wie Telefonnummer und E-Mail-Adresse. Sind Sie bei uns registriert, können Sie auf Inhalte und Leistungen zugreifen, die wir nur registrierten Nutzern anbieten. Angemeldete Nutzer haben zudem die Möglichkeit, bei Bedarf die bei Registrierung angegebenen Daten jederzeit zu ändern oder zu löschen. Selbstverständlich erteilen wir Ihnen darüber hinaus jederzeit Auskunft über die von uns über Sie gespeicherten personenbezogenen Daten. Gerne berichtigen bzw. löschen wir diese auch auf Ihren Wunsch, soweit keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Zur Kontaktaufnahme in diesem Zusammenhang nutzen Sie bitte die am Ende dieser Datenschutzerklärung angegebenen Kontaktdaten.<br />
              <br />
              <b>SSL-Verschlüsselung</b><br />
              Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.<br />
              <br />
              <b>Kontakt</b><br />
              Treten Sie per E-Mail mit uns in Kontakt, werden die von Ihnen gemachten Angaben zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.
            </s.TwoColParagraph>
          {/* prettier-ignore */}
          <s.TwoColParagraph>
              <b>Löschung bzw. Sperrung der Daten</b><br />
              Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit. Wir speichern Ihre personenbezogenen Daten daher nur so lange, wie dies zur Erreichung der hier genannten Zwecke erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen vielfältigen Speicherfristen vorsehen. Nach Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.<br />
              <br />
              <b>Ihre Rechte auf Auskunft, Berichtigung, Sperre, Löschung und Widerspruch</b><br />
              Sie haben das Recht, jederzeit Auskunft über Ihre bei uns gespeicherten personenbezogenen Daten zu erhalten. Ebenso haben Sie das Recht auf Berichtigung, Sperrung oder, abgesehen von der vorgeschriebenen Datenspeicherung zur Geschäftsabwicklung, Löschung Ihrer personenbezogenen Daten. Bitte wenden Sie sich dazu an unseren Datenschutzbeauftragten. Die Kontaktdaten finden Sie ganz unten.<br />
              Damit eine Sperre von Daten jederzeit berücksichtigt werden kann, müssen diese Daten zu Kontrollzwecken in einer Sperrdatei vorgehalten werden. Sie können auch die Löschung der Daten verlangen, soweit keine gesetzliche Archivierungsverpflichtung besteht. Soweit eine solche Verpflichtung besteht, sperren wir Ihre Daten auf Wunsch.<br />
              Sie können Änderungen oder den Widerruf einer Einwilligung durch entsprechende Mitteilung an uns mit Wirkung für die Zukunft vornehmen.<br />
              <br />
              <b>Änderung unserer Datenschutzbestimmungen</b><br />
              Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z. B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.<br />
              <br />
              <b>Fragen an den Datenschutzbeauftragten</b><br />
              Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail:
            </s.TwoColParagraph>
        </s.TwoColWrapper>
      </s.TextPageScrollContainer>
    </s.TextPageContentContainer>
  );
};

export default Datenschutz;
