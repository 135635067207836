//* third party packages
import React from "react";

//* components
import * as s from "components/Apps/Main/Pages/Pages.styled";
import { ReactComponent as SoundOff } from "assets/icons/soundoff.svg";
import { ReactComponent as SoundOn } from "assets/icons/soundon.svg";

//* other
import FrackmannHaengt from "assets/video/Frackmann_Hängt_Paribus_CLEAN.mp4";

const Biografie: React.FunctionComponent = () => {
  const [muted, setMuted] = React.useState(true);

  const handleMuteClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setMuted(!muted);
  };
  return (
    <s.ImagePageScrollContainer>
      <s.ImagePageContentContainer>
        <s.ImagePageContentWrapper>
          <s.BiografieImageWrapper>
            <s.BiografieVideoWrapper>
              <s.BiografieVideo
                autoPlay={true}
                controls={false}
                playsInline={true}
                muted={muted}
              >
                <source src={FrackmannHaengt} type="video/mp4" />
              </s.BiografieVideo>
              <s.BiografieVolumeButton onClick={handleMuteClick}>
                {muted ? (
                  <SoundOff className={"homepage-svg"} title={"Ton an"} />
                ) : (
                  <SoundOn className={"homepage-svg"} title={"Ton aus"} />
                )}
              </s.BiografieVolumeButton>
            </s.BiografieVideoWrapper>
          </s.BiografieImageWrapper>
          <s.BiografieTextWrapper>
            <s.TextPageHeadline>Biografie</s.TextPageHeadline>
            {/* prettier-ignore */}
            <s.BiografieText>
              <s.BiografieTextScrollContainer>
                1944 geboren in Plauen i. V.<br />
                Studien an der Hochschule für bildende Künste Hamburg (Thiemann, Brock, Bill, Bense, Bakema) und an der Universität Hamburg (Kunstgeschichte und Philosophie).<br />
                Arbeitsstipendium für bildende Kunst der Stadt Hamburg. Objektförderung der Kulturbehörde Hamburg.<br />
                Kunst im öffentlichen Raum.<br />
                Projektförderung der Hamburgischen Kulturstiftung Architektur und Malerei. Foyer Rouge.<br />
                Kammerspiele Szenische Projekte und freie Mitarbeit bei Architekturprojekten.<br />
                Lebt in Hamburg.
                <h3>Einzelausstellungen ( Auswahl )</h3>
                <s.YearAndText><s.Year>1974</s.Year> <s.YearText>Galerie Gunter Sachs Hamburg</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1975</s.Year> <s.YearText>Galerie Heike Curtze Düsseldorf</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1977</s.Year> <s.YearText>Galerie Haus 11 Karlsruhe</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1978</s.Year> <s.YearText>Galerie und Edition A München</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1978</s.Year> <s.YearText>Galerie Heike Curtze Wien, Jagdzyklen</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1979</s.Year> <s.YearText>Galerie am Markt Schwäbisch Hall</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1980</s.Year> <s.YearText>Galerie Annasäule Innsbruck, Malerei</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1983</s.Year> <s.YearText>Hamburger Kunsthalle Standpunkte, Bilder 1978-1983</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1983</s.Year> <s.YearText>Zehnthaus Jockrim, Zeichnungen 1978-1983</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1983</s.Year> <s.YearText>CC-Verlag Hamburg, Frackmann Rucksack Lehmann Schriften</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1984</s.Year> <s.YearText>Gnadenkirche St.Pauli Nord, Altarbild Klangstücke</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1985</s.Year> <s.YearText>Galerie Karin Bolz Mülheim, Malerei</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1986</s.Year> <s.YearText>Galerie Linde Lübeck, Zyklus Harmonie und Irrtum</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1987</s.Year> <s.YearText>Kunsthaus Hamburg, Prometheus bringt die Erde dem Feuer. Malerei</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1988</s.Year> <s.YearText>Hamburger Kunsthalle K3, Bilder 1988/89 Malerei</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1989</s.Year> <s.YearText>Galerie Sima Nürnberg, Fotogramme Malerei</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1990</s.Year> <s.YearText>Galerie Karin Nowikowsky Hamburg, Malerei und Objekt</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1991</s.Year> <s.YearText>Galerie Wolf Düsseldorf, Geschichtsnotizen. Malerei</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1992</s.Year> <s.YearText>Suermondt-Ludwig-Museum Aachen, Bilder und Architekturmodelle</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1992</s.Year> <s.YearText>Kunsthalle zu Kiel, Panoramen und Zeichnungen</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1993</s.Year> <s.YearText>Galerie Linde Lübeck, Malerei</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1993</s.Year> <s.YearText>Galerie M 20 Hamburg, Fotografien 1970-1992 Mediale Hamburg</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1993</s.Year> <s.YearText>Hamburger Kammerspiele Foyer Rouge, Bilder für eine Nacht</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1997</s.Year> <s.YearText>Galerie Linde Lübeck, Malerei</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1999</s.Year> <s.YearText>Galerie Joanna Kunstmann Santanyi Pinturas oscuras</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>2000</s.Year> <s.YearText>Galerie Linde Lübeck, Malerei und Zeichnung</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>2000</s.Year> <s.YearText>Anna Augstein Fine Arts Hamburg und Berlin, Malerei und Zeichnung</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>2000</s.Year> <s.YearText>St.Annen-Museum Lübeck, Malerei Fotografie Zeichnung</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>2001</s.Year> <s.YearText>Sothebys Hamburg, Malerei und Zeichnung</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>2002</s.Year> <s.YearText>Kunstverein Pforzheim, Malerei und Fotografie</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>2006</s.Year> <s.YearText>Museum für Kunst und Gewerbe Hamburg, Fotografie</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>2009</s.Year> <s.YearText>Deichtorhallen Hamburg Sammlung Falckenberg, Malerei Objekt</s.YearText></s.YearAndText>
                <h3>Ausstellungsbeteiligungen ( Auswahl )</h3>
                <s.YearAndText><s.Year>1971</s.Year> <s.YearText>Kunstverein Hamburg Laserprojektionen mit Harry Mayer</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1973</s.Year> <s.YearText>Forum junger Kunst Bochum Laserlichtgrafiken mit Harry Mayer</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1974</s.Year> <s.YearText>2.Intergraphy 74 Katowice</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1976</s.Year> <s.YearText>Galerie Academia Salzburg</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1977</s.Year> <s.YearText>espaces 77 Paris</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1977</s.Year> <s.YearText>Chimären Hamburg Kippenberger präsentiert sein Abschiedsgeschenk.</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1983</s.Year> <s.YearText>Interversa Hamburg und Hamburger Kunsthalle Zeichner in Hamburg</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1983</s.Year> <s.YearText>Hamburger Kunsthalle ars viva</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1983</s.Year> <s.YearText>Deutscher Künstlerbund Berlin</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1984</s.Year> <s.YearText>Kunstverein Hamburg Im toten Winkel</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1984</s.Year> <s.YearText>Kunstverein Düsseldorf Kunstlandschaft BRD</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1985</s.Year> <s.YearText>Museo de Arte Moderno Mexico City Tiempo Circular</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1985</s.Year> <s.YearText>Goethe-Institute Zagreb und Belgrad Ausschnitte</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1986</s.Year> <s.YearText>Lothringer Straße München Gastspiel</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1986</s.Year> <s.YearText>Artware Hannover</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1987</s.Year> <s.YearText>Kunst-und Museumsverein Wuppertal Vom Essen und Trinken</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1987</s.Year> <s.YearText>Gesellschaft für aktuelle Kunst Bremen Der Raum</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1988</s.Year> <s.YearText>Kunsthaus Hamburg Arbeit in Geschichte Geschichte in Arbeit</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1988</s.Year> <s.YearText>Evangelische Akademie Hamburg Apokalypse+Krieg und Frieden</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1989</s.Year> <s.YearText>Mathäikirche Berlin Engel 89</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1989</s.Year> <s.YearText>KX Hamburg 1.September 4:45 1939/1989</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1991</s.Year> <s.YearText>Alter Kunstverein Hamburg Konkret Literaturverlag Kunst gegen den Golfkrieg</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1993</s.Year> <s.YearText>Galerie Hoffmeister Lüdenscheid Kontraste</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1994</s.Year> <s.YearText>Institut für angewandte Botanik Hamburg Poiesis</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1995</s.Year> <s.YearText>Kunsthaus Hamburg Hamburgische Kulturstiftung und NDR Kunststreifzüge</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1996</s.Year> <s.YearText>ACC Galerie Weimar Wahlverwandtschaften mit J.Bornefeld und RR.Odenwald</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1996</s.Year> <s.YearText>Stadtmuseum Jena Von Schlachten Vom Schlachten</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>1997</s.Year> <s.YearText>Literaturhaus Hamburg Zeitsprünge</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>2002</s.Year> <s.YearText>Altonaer Museum Deep Darkness Von der Motivation Böses zu tun</s.YearText></s.YearAndText>
                <s.YearAndText><s.Year>2009</s.Year> <s.YearText>Sammlung Falckenberg Harburg mit N.Frensch und I. zu Schleswig Holstein</s.YearText></s.YearAndText>
                <h3>Öffentliche Sammlungen</h3>
                <s.YearText>Sammlung Deutsche Bank</s.YearText>
                <s.YearText>Morat Institut für Kunst und Kunstwissenschaft Freiburg</s.YearText>
                <s.YearText>St.Annen-Museum für Kunst und Kulturgeschichte der Hansestadt Lübeck</s.YearText>
                <s.YearText>Hamburger Kunsthalle</s.YearText>
                <s.YearText>Sammlung Falckenberg</s.YearText>
                <s.YearText>Museum für Kunst und Gewerbe Hamburg</s.YearText>
                <s.YearText>Sammlung Norddeutscher Rundfunk</s.YearText>
                <s.YearText>Kunsthalle zu Kiel</s.YearText>
                <h3>Film</h3>
                <s.YearText>Schöne Probleme Harald Frackmann Maler und Poet</s.YearText>
                <s.YearText>Ein Filmporträt von Lucas Maria Böhmer für N3</s.YearText>
                <s.YearText>Sendung 25.9.1994 Wiederholungen 15.1.1995 / 15.1.2000</s.YearText>
              </s.BiografieTextScrollContainer>
            </s.BiografieText>
          </s.BiografieTextWrapper>
        </s.ImagePageContentWrapper>
      </s.ImagePageContentContainer>
    </s.ImagePageScrollContainer>
  );
};

export default Biografie;
