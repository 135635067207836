//* third party packages
import React from "react";

//* components
import CollectionRenderer from "components/Apps/Main/Gallery/CollectionRenderer";
import CollectionRendererGrid from "components/Apps/Main/Gallery/CollectionRendererGrid";
import ExhibitionWall from "components/Apps/Main/Components/ExhibitionWall";
// import FooterTablet from "components/Apps/Main/Components/Footer/FooterTablet";

//* other
import { CollectionsByFilter } from "assets/gallery/database-v2";
import { Context } from "components/Apps/Main/Context";

const Zyklen: React.FunctionComponent = () => {
  const {
    breakPoints: { isDesktop },
  } = React.useContext(Context);

  if (isDesktop)
    return (
      <>
        <CollectionRenderer
          flatCollectionByFilter={
            CollectionsByFilter.gallery19801990.flatCollection
          }
          updateGroupInformation={false}
        />
        {/*<FooterTablet />*/}
      </>
    );

  return (
    <ExhibitionWall>
      <CollectionRendererGrid
        collectionByFilter={CollectionsByFilter.gallery19801990}
        updateGroupInformation={false}
      />
      {/*<FooterTablet />*/}
    </ExhibitionWall>
  );
};

export default Zyklen;
