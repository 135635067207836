const work20002020 = [
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Roter Fluß",
      description: "180 x 192",
      technique: "Öl auf Karton",
      workNo: "58",
      year: "2008/09",
    },
    images: ["58_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "130 x 140",
      technique: "Öl auf Glas",
      workNo: "63",
      year: "2008",
    },
    images: ["63_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Holz",
      workNo: "64",
      year: "2008",
    },
    images: ["64_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "175 x 120",
      technique: "Öl auf Leinwand",
      workNo: "65",
      year: "2000/04",
    },
    images: ["65_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "175 x 120",
      technique: "Öl auf Leinwand",
      workNo: "66",
      year: "2000/04",
    },
    images: ["66_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "175 x 120",
      technique: "Öl auf Leinwand",
      workNo: "67",
      year: "2000/04",
    },
    images: ["67_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "175 x 120",
      technique: "Öl auf Leinwand",
      workNo: "68",
      year: "2000/04",
    },
    images: ["68_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "175 x 120",
      technique: "Öl auf Leinwand",
      workNo: "69",
      year: "2000/04",
    },
    images: ["69_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "175 x 120",
      technique: "Öl auf Leinwand",
      workNo: "70",
      year: "2000/04",
    },
    images: ["70_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "175 x 120",
      technique: "Öl auf Leinwand",
      workNo: "72",
      year: "2000/02",
    },
    images: ["72_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "175 x 120",
      technique: "Öl auf Leinwand",
      workNo: "74",
      year: "2000/04",
    },
    images: ["74_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "175 x 120",
      technique: "Öl auf Leinwand",
      workNo: "75",
      year: "2003/04",
    },
    images: ["75_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "175 x 120",
      technique: "Öl auf Leinwand",
      workNo: "76",
      year: "2003/04",
    },
    images: ["76_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "175 x 120",
      technique: "Öl auf Leinwand",
      workNo: "77",
      year: "2000/12",
    },
    images: ["77_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "175 x 120",
      technique: "Öl auf Leinwand",
      workNo: "78",
      year: "2003/14",
    },
    images: ["78_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "175 x 120",
      technique: "Öl auf Leinwand",
      workNo: "80",
      year: "2000/04",
    },
    images: ["80_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "160 x 100",
      technique: "Öl auf Holz",
      workNo: "97",
      year: "2000/02",
    },
    images: ["97_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "160 x 100",
      technique: "Öl auf Holz",
      workNo: "98",
      year: "2000/02",
    },
    images: ["98_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "120 x 150 ",
      technique: "Öl auf Holz",
      workNo: "137",
      year: "2000/02",
    },
    images: ["137_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "120 x 150 ",
      technique: "Öl auf Holz",
      workNo: "138",
      year: "2000/02",
    },
    images: ["138_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "170 x 142",
      technique: "Öl auf Karton",
      workNo: "141",
      year: "",
    },
    images: ["141_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "180 x 190",
      technique: "Öl auf Karton",
      workNo: "148",
      year: "2000/10",
    },
    images: ["148_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "180 x 190",
      technique: "Öl auf Karton",
      workNo: "149",
      year: "2008/09",
    },
    images: ["149_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "180 x 190",
      technique: "Öl auf Karton",
      workNo: "150",
      year: "2000/10",
    },
    images: ["150_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "180 x 190",
      technique: "Öl auf Karton",
      workNo: "151",
      year: "2009",
    },
    images: ["151_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Reusengebiet",
      description: "200 x 70",
      technique: "Öl auf Holz",
      workNo: "160",
      year: "",
    },
    images: ["160_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ornament unter den Bändern",
      description: "200 x 70",
      technique: "Öl auf Holz",
      workNo: "165",
      year: "2000/01",
    },
    images: ["165_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "238",
      year: "2008",
    },
    images: ["238_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Holz",
      workNo: "240",
      year: "2004",
    },
    images: ["240_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "242",
      year: "2008",
    },
    images: ["242_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "130 x 100",
      technique: "Öl auf Hartfaser",
      workNo: "243",
      year: "2006",
    },
    images: ["243_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "247",
      year: "2002",
    },
    images: ["247_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "251",
      year: "2004",
    },
    images: ["251_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "256",
      year: "2008",
    },
    images: ["256_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "130 x 100",
      technique: "Öl auf Hartfaser",
      workNo: "264",
      year: "2006",
    },
    images: ["264_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "120 x 100",
      technique: "Öl auf Leinwand",
      workNo: "280",
      year: "2005/06",
    },
    images: ["280_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 150",
      technique: "Öl auf Leinwand",
      workNo: "286",
      year: "2008",
    },
    images: ["286_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 130",
      technique: "Öl auf Hartfaser",
      workNo: "308",
      year: "2000/06",
    },
    images: ["308_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 150",
      technique: "Öl auf Hartfaser",
      workNo: "316",
      year: "2000/06",
    },
    images: ["316_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 150",
      technique: "Öl auf Hartfaser",
      workNo: "318",
      year: "2000/06",
    },
    images: ["318_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 150",
      technique: "Öl auf Hartfaser",
      workNo: "319",
      year: "2000/06",
    },
    images: ["319_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 150",
      technique: "Öl auf Hartfaser",
      workNo: "320",
      year: "2000/06",
    },
    images: ["320_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 150",
      technique: "Öl auf Hartfaser",
      workNo: "321",
      year: "2000/06",
    },
    images: ["321_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 150",
      technique: "Öl auf Hartfaser",
      workNo: "322",
      year: "2000/06",
    },
    images: ["322_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 150",
      technique: "Öl auf Hartfaser",
      workNo: "323",
      year: "2000/06",
    },
    images: ["323_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 150",
      technique: "Öl auf Hartfaser",
      workNo: "324",
      year: "2000/06",
    },
    images: ["324_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 150",
      technique: "Öl auf Hartfaser",
      workNo: "326",
      year: "2000/06",
    },
    images: ["326_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 84 ",
      technique: "Öl auf Holz",
      workNo: "329",
      year: "2000/02",
    },
    images: ["329_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "331",
      year: "2008",
    },
    images: ["331_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "335",
      year: "2000",
    },
    images: ["335_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "337",
      year: "2008",
    },
    images: ["337_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "345",
      year: "2008",
    },
    images: ["345_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "346",
      year: "2002",
    },
    images: ["346_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "349",
      year: "2002/03",
    },
    images: ["349_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "364",
      year: "2004",
    },
    images: ["364_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "365",
      year: "2000",
    },
    images: ["365_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "374",
      year: "2008",
    },
    images: ["374_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Leinwand",
      workNo: "382",
      year: "2008",
    },
    images: ["382_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "390",
      year: "2003",
    },
    images: ["390_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "391",
      year: "2005",
    },
    images: ["391_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "392",
      year: "2004",
    },
    images: ["392_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "397",
      year: "",
    },
    images: ["397_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "402",
      year: "2003",
    },
    images: ["402_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "403",
      year: "2003",
    },
    images: ["403_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "405",
      year: "2004",
    },
    images: ["405_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "406",
      year: "2005/06",
    },
    images: ["406_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "414",
      year: "2001/02",
    },
    images: ["414_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "428",
      year: "2003/04",
    },
    images: ["428_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "434",
      year: "2003/04",
    },
    images: ["434_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "438",
      year: "2007",
    },
    images: ["438_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "442",
      year: "2005",
    },
    images: ["442_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "445",
      year: "2002",
    },
    images: ["445_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "446",
      year: "-",
    },
    images: ["446_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Gartenstück",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "454",
      year: "2003",
    },
    images: ["454_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "455",
      year: "2002",
    },
    images: ["455_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "456",
      year: "2003/04",
    },
    images: ["456_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "457",
      year: "2004",
    },
    images: ["457_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "530",
      year: "2001/02",
    },
    images: ["530_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "533",
      year: "",
    },
    images: ["533_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "567",
      year: "2007",
    },
    images: ["567_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "569",
      year: "2003/04",
    },
    images: ["569_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "570",
      year: "2003/04",
    },
    images: ["570_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "571",
      year: "2006",
    },
    images: ["571_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "572",
      year: "",
    },
    images: ["572_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "575",
      year: "2004",
    },
    images: ["575_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "579",
      year: "2000/03",
    },
    images: ["579_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "585",
      year: "2010/11",
    },
    images: ["585_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "587",
      year: "2003/04",
    },
    images: ["587_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "589",
      year: "2005",
    },
    images: ["589_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "596",
      year: "2000",
    },
    images: ["596_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "597",
      year: "2001/02",
    },
    images: ["597_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "599",
      year: "2001/02",
    },
    images: ["599_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "600",
      year: "2003/04",
    },
    images: ["600_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "607",
      year: "2002",
    },
    images: ["607_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "608",
      year: "2002",
    },
    images: ["608_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "609",
      year: "2002",
    },
    images: ["609_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "617",
      year: "2003/04",
    },
    images: ["617_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 64",
      technique: "Öl auf Hartfaser",
      workNo: "620",
      year: "2010",
    },
    images: ["620_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 64",
      technique: "Öl auf Hartfaser",
      workNo: "621",
      year: "2010",
    },
    images: ["621_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 64",
      technique: "Öl auf Hartfaser",
      workNo: "621",
      year: "2010",
    },
    images: ["621_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 64",
      technique: "Öl auf Hartfaser",
      workNo: "624",
      year: "2010",
    },
    images: ["624_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Hülsenernte",
      description: "80 x 112",
      technique: "Öl auf Holz",
      workNo: "627",
      year: "",
    },
    images: ["627_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "630",
      year: "2007",
    },
    images: ["630_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "634",
      year: "2003",
    },
    images: ["634_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "637",
      year: "2007",
    },
    images: ["637_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 64",
      technique: "Öl auf Hartfaser",
      workNo: "641",
      year: "2010",
    },
    images: ["641_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 64",
      technique: "Öl auf Hartfaser",
      workNo: "642",
      year: "2010",
    },
    images: ["642_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "90 x 64",
      technique: "Öl auf Hartfaser",
      workNo: "643",
      year: "2011",
    },
    images: ["643_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 64",
      technique: "Öl auf Hartfaser",
      workNo: "646",
      year: "2010",
    },
    images: ["646_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 64",
      technique: "Öl auf Hartfaser",
      workNo: "648",
      year: "2010",
    },
    images: ["648_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Hartfaser",
      workNo: "649",
      year: "",
    },
    images: ["649_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Hartfaser",
      workNo: "650",
      year: "",
    },
    images: ["650_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "651",
      year: "2008",
    },
    images: ["651_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "652",
      year: "2001/02",
    },
    images: ["652_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "653",
      year: "2001/02",
    },
    images: ["653_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "659",
      year: "2013",
    },
    images: ["659_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "660",
      year: "2009",
    },
    images: ["660_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "661",
      year: "2001/02",
    },
    images: ["661_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "662",
      year: "2013",
    },
    images: ["662_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "663",
      year: "2005",
    },
    images: ["663_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "665",
      year: "",
    },
    images: ["665_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "666",
      year: "",
    },
    images: ["666_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "667",
      year: "",
    },
    images: ["667_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "668",
      year: "",
    },
    images: ["668_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "671",
      year: "",
    },
    images: ["671_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 80",
      technique: "Öl auf Karton",
      workNo: "676",
      year: "2011",
    },
    images: ["676_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "88 x 98",
      technique: "Öl auf Karton",
      workNo: "677",
      year: "",
    },
    images: ["677_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 80",
      technique: "Öl auf Karton",
      workNo: "678",
      year: "2011",
    },
    images: ["678_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "88 x 98",
      technique: "Öl auf Karton",
      workNo: "679",
      year: "",
    },
    images: ["679_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "85 x 75",
      technique: "Öl auf Karton",
      workNo: "682",
      year: "2010",
    },
    images: ["682_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "90 x 72",
      technique: "Öl auf Karton",
      workNo: "685",
      year: "",
    },
    images: ["685_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "90 x 72",
      technique: "Öl auf Karton",
      workNo: "688",
      year: "",
    },
    images: ["688_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "85 x 73",
      technique: "Öl auf Karton",
      workNo: "690",
      year: "2010",
    },
    images: ["690_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "90 x 72",
      technique: "Öl auf Karton",
      workNo: "692",
      year: "",
    },
    images: ["692_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "98 x 87",
      technique: "Öl auf Karton",
      workNo: "696",
      year: "2009",
    },
    images: ["696_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Hartfaser",
      workNo: "701",
      year: "",
    },
    images: ["701_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "702",
      year: "",
    },
    images: ["702_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Hartfaser",
      workNo: "704",
      year: "",
    },
    images: ["704_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Hartfaser",
      workNo: "705",
      year: "",
    },
    images: ["705_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "708",
      year: "2010/11",
    },
    images: ["708_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "709",
      year: "2010/11",
    },
    images: ["709_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "710",
      year: "2010/11",
    },
    images: ["710_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "711",
      year: "2001/02",
    },
    images: ["711_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "713",
      year: "2013",
    },
    images: ["713_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "715",
      year: "2008",
    },
    images: ["715_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "716",
      year: "2001/02",
    },
    images: ["716_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "717",
      year: "2001/02",
    },
    images: ["717_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "718",
      year: "2001/02",
    },
    images: ["718_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "719",
      year: "2001/02",
    },
    images: ["719_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "720",
      year: "2001/02",
    },
    images: ["720_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "722",
      year: "2010",
    },
    images: ["722_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "725",
      year: "2013",
    },
    images: ["725_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Im Winter",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "726",
      year: "2001/02",
    },
    images: ["726_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "728",
      year: "2005",
    },
    images: ["728_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "730",
      year: "2012",
    },
    images: ["730_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "731",
      year: "2013",
    },
    images: ["731_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "732",
      year: "2001/02",
    },
    images: ["732_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "733",
      year: "2003/04",
    },
    images: ["733_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "735",
      year: "2009",
    },
    images: ["735_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "740",
      year: "",
    },
    images: ["740_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "50 x 75",
      technique: "Öl auf Karton",
      workNo: "747",
      year: "2003/04",
    },
    images: ["747_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "50 x 75",
      technique: "Öl auf Karton",
      workNo: "748",
      year: "2003/03",
    },
    images: ["748_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "50 x 75",
      technique: "Öl auf Karton",
      workNo: "749",
      year: "2003/03",
    },
    images: ["749_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "50 x 75",
      technique: "Öl auf Karton",
      workNo: "753",
      year: "2003/03",
    },
    images: ["753_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "50 x 75",
      technique: "Öl auf Karton",
      workNo: "754",
      year: "2003/03",
    },
    images: ["754_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "50 x 75",
      technique: "Öl auf Karton",
      workNo: "760",
      year: "2002/04",
    },
    images: ["760_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "50 x 75",
      technique: "Öl auf Karton",
      workNo: "762",
      year: "2002/03",
    },
    images: ["762_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "50 x 75",
      technique: "Öl auf Karton",
      workNo: "765",
      year: "2003/04",
    },
    images: ["765_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "771",
      year: "2006",
    },
    images: ["771_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "50 x 100",
      technique: "Öl auf Hartfaser",
      workNo: "779",
      year: "2002/03",
    },
    images: ["779_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "50 x 100",
      technique: "Öl auf Hartfaser",
      workNo: "780",
      year: "2003",
    },
    images: ["780_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "90 x 72",
      technique: "Öl auf Karton",
      workNo: "793",
      year: "",
    },
    images: ["793_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "85 x 74",
      technique: "Öl auf Karton",
      workNo: "803",
      year: "2010",
    },
    images: ["803_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 80",
      technique: "Öl auf Karton",
      workNo: "804",
      year: "2011",
    },
    images: ["804_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "806",
      year: "2005",
    },
    images: ["806_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "808",
      year: "2001/02",
    },
    images: ["808_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "812",
      year: "2000",
    },
    images: ["812_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "813",
      year: "",
    },
    images: ["813_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "819",
      year: "2008",
    },
    images: ["819_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "98 x 80",
      technique: "Öl auf Karton",
      workNo: "833",
      year: "2006",
    },
    images: ["833_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "90 x 64",
      technique: "Öl auf Hartfaser",
      workNo: "844",
      year: "2010",
    },
    images: ["844_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "90 x 64",
      technique: "Öl auf Hartfaser",
      workNo: "845",
      year: "2010",
    },
    images: ["845_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "85 x 74",
      technique: "Öl auf Karton",
      workNo: "848",
      year: "2010",
    },
    images: ["848_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "85 x 74",
      technique: "Öl auf Karton",
      workNo: "849",
      year: "2010",
    },
    images: ["849_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "120 x 100",
      technique: "Öl auf Leinwand",
      workNo: "861",
      year: "2005/06",
    },
    images: ["861_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 120",
      technique: "Öl auf Leinwand",
      workNo: "869",
      year: "2005/06",
    },
    images: ["869_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Leinwand",
      workNo: "874",
      year: "2008",
    },
    images: ["874_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "85 x 138",
      technique: "Öl auf Karton",
      workNo: "882",
      year: "2008",
    },
    images: ["882_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "50 x 100",
      technique: "Öl auf Hartfaser",
      workNo: "888",
      year: "2002/03",
    },
    images: ["888_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "50 x 100",
      technique: "Öl auf Hartfaser",
      workNo: "889",
      year: "2003",
    },
    images: ["889_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
];

export default work20002020;
