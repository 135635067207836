const palette = {
  white: "rgb(255, 255, 255)",
  cream: "#FAF8F4",
  grey: "#C6C6C6",
  offBlack: "#121212",
};

const elements = {
  mainBackground: palette.white,
  menuShadowColor: "rgba(12, 12, 12, 0.1)",
  textColor: palette.offBlack,
};

const theme = {
  color: {
    palette,
    elements,
  },
  font: {
    family: [
      '"Open Sans"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Helvetica",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    lineHeight: 1.5,
  },
};

export type TTheme = typeof theme;
export default theme;
