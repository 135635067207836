//* third party packages
import React from "react";

//* components
import * as s from "components/Apps/Main/Pages/Pages.styled";
import * as links from "components/Apps/Main/Components/Links";

//* other
import { ReactComponent as Envelope } from "assets/icons/evnelope.svg";
import { ReactComponent as Instagram } from "assets/icons/Instagram.svg";
// import { ReactComponent as TikTok } from "assets/icons/tiktok.svg";
import { ReactComponent as Facebook } from "assets/icons/teenyicons_facebook-outline.svg";

const Icons: React.FunctionComponent = () => {
  return (
    <s.KontaktIconContainer>
      <links.Mail>
        <Envelope title={"Kontakt"} />
      </links.Mail>
      <links.Instagram>
        <Instagram title={"Instagram"} />
      </links.Instagram>
      {/*<links.TikTok>*/}
      {/*  <TikTok />*/}
      {/*</links.TikTok>*/}
      <links.Facebook>
        <Facebook title={"Facebook"} />
      </links.Facebook>
    </s.KontaktIconContainer>
  );
};

export default Icons;
