import React from "react";
import Flicking from "@egjs/react-flicking";

interface IContext {
  innerWidth: number;
  menuOpen: boolean;
  setMenuOpen: (v: boolean) => void;
  toggleMenuOpen: () => void;
  filterOpen: boolean;
  setFilterOpen: (v: boolean) => void;
  toggleFilterOpen: () => void;
  imageDetailId: string | null;
  setImageDetailId: (v: string | null) => void;
  flickingRef: React.RefObject<Flicking>;
  lightTheme: boolean;
  setLightTheme: (v: boolean) => void;
  exhibitionWallRef: React.MutableRefObject<HTMLDivElement | undefined>;
  currentGroup: string;
  setCurrentGroup: (v: string) => void;
  scrollToGroupName: string;
  setScrollToGroupName: (v: string) => void;
  breakPoints: {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
  };
}

export const Context = React.createContext<IContext>({
  innerWidth: window.innerWidth,
  menuOpen: false,
  setMenuOpen: (v: boolean) => {},
  toggleMenuOpen: () => {},
  filterOpen: false,
  setFilterOpen: (v: boolean) => {},
  toggleFilterOpen: () => {},
  imageDetailId: null,
  setImageDetailId: (v: string | null) => {},
  // @ts-ignore
  flickingRef: undefined,
  lightTheme: true,
  setLightTheme: (v: boolean) => {},
  // @ts-ignore
  exhibitionWallRef: undefined,
  currentGroup: "",
  setCurrentGroup: (v: string) => {},
  breakPoints: {
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  },
});

const calculateBreakPoints = (innerWidth: number) => {
  let isMobile = false;
  let isTablet = false;
  let isDesktop = false;
  if (innerWidth <= 575) {
    isMobile = true;
  } else if (innerWidth <= 991) {
    isTablet = true;
  } else {
    isDesktop = true;
  }
  return { isMobile, isTablet, isDesktop };
};

const AppContext: React.FunctionComponent<TReactPropsWithChildren> = (
  props
) => {
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);

  const [menuOpen, setMenuOpen] = React.useState(false);
  const toggleMenuOpen = React.useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  const [filterOpen, setFilterOpen] = React.useState(false);
  const toggleFilterOpen = React.useCallback(() => {
    setFilterOpen(!filterOpen);
  }, [filterOpen]);

  const [breakPoints, setBreakPoints] = React.useState(
    calculateBreakPoints(innerWidth)
  );

  const [imageDetailId, setImageDetailId] = React.useState<string | null>(null);

  const [lightTheme, setLightTheme] = React.useState<boolean>(true);

  const exhibitionWallRef = React.useRef<HTMLDivElement>();
  const flickingRef = React.createRef<Flicking>();

  const [currentGroup, setCurrentGroup] = React.useState<string>("");

  const [scrollToGroupName, setScrollToGroupName] = React.useState<string>("");

  React.useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(
    () => setBreakPoints(calculateBreakPoints(innerWidth)),
    [innerWidth]
  );

  return (
    <Context.Provider
      value={{
        innerWidth,
        breakPoints,
        menuOpen,
        setMenuOpen,
        toggleMenuOpen,
        filterOpen,
        setFilterOpen,
        toggleFilterOpen,
        imageDetailId,
        setImageDetailId,
        flickingRef,
        lightTheme,
        setLightTheme,
        exhibitionWallRef,
        currentGroup,
        setCurrentGroup,
        scrollToGroupName,
        setScrollToGroupName,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default AppContext;
