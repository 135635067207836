//* third party packages
import React from "react";

//* components
import * as s from "components/Apps/Main/Gallery/CollectionRenderer.styled";

//* other
import useDraggable from "use-draggable-hook";
import { Context } from "components/Apps/Main/Context";

interface IProps {
  total: number;
}

const CollectionRendererScrollbar: React.FunctionComponent<IProps> = ({
  total,
}) => {
  const { flickingRef, imageDetailId } = React.useContext(Context);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [wrapperWidth, setWrapperWidth] = React.useState(0);

  React.useEffect(() => {
    const resizeHandler = () => {
      if (wrapperRef.current) {
        setWrapperWidth(wrapperRef.current.getBoundingClientRect().width - 60);
      }
    };
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [wrapperRef]);

  const { target: handleRef, setPosition } = useDraggable<HTMLDivElement>({
    direction: "horizontal",
    stepSize: wrapperWidth / (total - 1),
    maxDistance: { x: { min: 0, max: wrapperWidth } },
    onStart: () => {
      window.collectionRendererChangeByDraggable = true;
    },
    onEnd: (target, pos, setPosition) => {
      if (window.collectionRendererChangeByDraggable) {
        const index = pos[0] / (wrapperWidth / (total - 1));
        // console.log(`index: ${index}, pos[0]: ${pos[0]}`);
        flickingRef.current?.moveTo(Math.round(index)).catch(() => {});
      }
    },
  });

  React.useEffect(() => {
    if (wrapperRef.current) {
      setWrapperWidth(wrapperRef.current.getBoundingClientRect().width - 60);
    }
  }, [wrapperRef]);

  const currentIndex = flickingRef.current?.index;

  React.useEffect(() => {
    if (
      typeof currentIndex === "number" &&
      !window.collectionRendererChangeByDraggable
    ) {
      // console.log(`current: ${currentIndex}, x: ${x}, wrapperWidth: ${wrapperWidth}`);
      const x = (wrapperWidth / (total - 1)) * currentIndex;
      setPosition([x, 0]);
    }
    window.collectionRendererChangeByDraggable = false;
  }, [total, wrapperWidth, setPosition, currentIndex]);

  const handleClickHandler = React.useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const wrapperClickHandler = React.useCallback(
    (e: React.MouseEvent) => {
      window.collectionRendererChangeByDraggable = true;
      const stepWidth = wrapperWidth / (total - 1);
      let index = Math.floor(
        (e.clientX - e.currentTarget.getBoundingClientRect().x) / stepWidth
      );
      index = index > total - 1 ? total - 1 : index;
      // console.log(e.currentTarget.getBoundingClientRect().x);
      // console.log(
      //   `index: ${index}, e.clientX: ${e.clientX}, stepWidth: ${stepWidth}`
      // );
      flickingRef.current?.moveTo(Math.round(index)).catch(() => {});
      setPosition([stepWidth * index, 0]);
    },
    [flickingRef, total, wrapperWidth, setPosition]
  );

  return (
    <s.ScrollbarWrapper
      ref={wrapperRef}
      key={wrapperWidth}
      hide={!!imageDetailId}
      onClick={wrapperClickHandler}
    >
      <s.ScrollbarHandle ref={handleRef} onClick={handleClickHandler}>
        <s.ScrollbarHandleInner />
      </s.ScrollbarHandle>
    </s.ScrollbarWrapper>
  );
};

export default CollectionRendererScrollbar;
