//* third party packages
import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

//* components
import * as s from "components/Apps/Main/styles";
import AppContext from "components/Apps/Main/Context";
import BackArrow from "components/Apps/Main/Pages/BackArrow";
import Bibliographie from "components/Apps/Main/Pages/Bibliographie";
import Biografie from "components/Apps/Main/Pages/Biografie";
import Datenschutz from "components/Apps/Main/Pages/Datenschutz";
import FilterMenu from "components/Apps/Main/Navbar/FilterMenu";
// import Footer from "components/Apps/Main/Components/Footer";
import Gemaelde from "components/Apps/Main/Gallery/Gemaelde";
import GroupInformation from "components/Apps/Main/Gallery/GroupInformation";
// import Home from "components/Apps/Main/Home";
import ImageDetail from "components/Apps/Main/Components/ImageDetail/ImageDetail";
import Impressum from "components/Apps/Main/Pages/Impressum";
import Kontakt from "components/Apps/Main/Pages/Kontakt";
import Kuratiert from "components/Apps/Main/Gallery/Kuratiert";
// import Lamp from "components/Apps/Main/Components/Lamp";
import MalereiCollage from "components/Apps/Main/Gallery/MalereiCollage";
import Menu from "components/Apps/Main/Menu";
import Navbar from "components/Apps/Main/Navbar";
import Werkauswahl from "components/Apps/Main/Gallery/Werkauswahl";
import Zyklen from "components/Apps/Main/Gallery/Zyklen";
import ThemeProvider from "components/Apps/Main/ThemeProvider";

//* other
import routes from "constants/routes";

const Main: React.FunctionComponent = () => {
  const docHeight = React.useCallback(() => {
    document.documentElement.style.setProperty(
      "--doc-height",
      `${window.innerHeight}px`
    );
  }, []);
  React.useEffect(() => docHeight(), [docHeight]);
  React.useEffect(() => {
    window.addEventListener("resize", docHeight);
    return () => window.removeEventListener("resize", docHeight);
  }, [docHeight]);

  return (
    <AppContext>
      <ThemeProvider>
        <s.GlobalStyle />
        <s.AppContainer>
          <BrowserRouter>
            <Routes>
              <Route
                element={
                  <>
                    <Navbar />
                    <Menu />
                    <FilterMenu />
                    <ImageDetail />
                    <Outlet />
                    <GroupInformation />
                    {/*<Footer />*/}
                    {/*<Lamp />*/}
                  </>
                }
              >
                <Route path={routes.home} element={<Werkauswahl />} />
                <Route path={routes.gallery19701980} element={<Kuratiert />} />
                <Route path={routes.gallery19801990} element={<Zyklen />} />
                <Route
                  path={routes.gallery19902000}
                  element={<MalereiCollage />}
                />
                <Route path={routes.gallery20002020} element={<Gemaelde />} />
              </Route>
              <Route
                element={
                  <>
                    <Navbar />
                    <Menu />
                    <Outlet />
                    {/*<Footer />*/}
                    {/*<Lamp />*/}
                    <BackArrow />
                  </>
                }
              >
                <Route path={routes.biografie} element={<Biografie />} />
                <Route path={routes.kontakt} element={<Kontakt />} />
                <Route
                  path={routes.literatur}
                  element={<Bibliographie />}
                />
                <Route path={routes.datenschutz} element={<Datenschutz />} />
                <Route path={routes.impressum} element={<Impressum />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </s.AppContainer>
      </ThemeProvider>
    </AppContext>
  );
};

export default Main;
