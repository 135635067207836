const work19801990 = [
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Notiz",
      description: "227 x 207",
      technique: "Öl auf Leinwand",
      workNo: "4",
      year: "1980/86",
    },
    images: ["4_Cut_DSCF0009.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Notiz",
      description: "290 x 200",
      technique: "Öl auf Molton",
      workNo: "5",
      year: "1983/84",
    },
    images: ["5_Cut_DSCF0010.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 70,
    scaleVerticalTablet: 75,
    scaleVerticalMobile: 80,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Dunkle Notiz",
      description: "175 x 245",
      technique: "Öl auf Hartfaser",
      workNo: "6",
      year: "1983/84",
    },
    images: ["6_Cut_DSCF0011.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Inszenierung",
      description: "175 x 245",
      technique: "Öl auf Hartfaser",
      workNo: "8",
      year: "1983/84",
    },
    images: ["8_Cut_DSCF0014.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Dunkle Notiz",
      description: "200 x 240",
      technique: "Öl auf Holz ",
      workNo: "12",
      year: "1980/83",
    },
    images: ["12_Cut_DSCF0019.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Bündel",
      description: "310 x 240",
      technique: "Öl auf Holz",
      workNo: "13",
      year: "1981",
    },
    images: ["13_Cut_DSCF0020.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 70,
    scaleVerticalTablet: 75,
    scaleVerticalMobile: 80,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Rekonvaleszenz",
      description: "200 x 290",
      technique: "Öl auf Molton",
      workNo: "17",
      year: "1984",
    },
    images: ["17_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 70,
    scaleVerticalTablet: 75,
    scaleVerticalMobile: 80,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Mills (?)",
      description: "200 x 80 ",
      technique: "Öl auf Holz",
      workNo: "21",
      year: "1980",
    },
    images: ["21_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 80 ",
      technique: "Öl auf Holz",
      workNo: "22",
      year: "1981",
    },
    images: ["22_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 240",
      technique: "Öl auf Holz",
      workNo: "25",
      year: "",
    },
    images: ["25_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 150",
      technique: "Öl auf Holz",
      workNo: "48",
      year: "1980/84",
    },
    images: ["48_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Raumrest",
      description: "200 x 98",
      technique: "Öl auf Holz",
      workNo: "52",
      year: "1981",
    },
    images: ["52_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Werkzeuge des Zeitgeistes",
      description: "200 x 90",
      technique: "Öl auf Holz",
      workNo: "95",
      year: "1982",
    },
    images: ["95_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "143 x 100",
      technique: "Öl auf Leinwand",
      workNo: "133",
      year: "1986",
    },
    images: ["133_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Erster Schnee",
      description: "100 x 150",
      technique: "Öl auf Hartfaser",
      workNo: "134",
      year: "",
    },
    images: ["134_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 120",
      technique: "Öl auf Holz",
      workNo: "145",
      year: "1982",
    },
    images: ["145_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Flammen",
      description: "200 x 86",
      technique: "Öl auf Holz",
      workNo: "170",
      year: "1981",
    },
    images: ["170_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Flammen",
      description: "200 x 90",
      technique: "Öl auf Holz",
      workNo: "230",
      year: "1981",
    },
    images: ["230_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "110 x 135",
      technique: "Öl auf Hartfaser",
      workNo: "306",
      year: "1980",
    },
    images: ["306_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Leinwand",
      workNo: "383",
      year: "1980",
    },
    images: ["383_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Leinwand",
      workNo: "387",
      year: "1980",
    },
    images: ["387_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Dunkler Rest",
      description: "130 x 100",
      technique: "Öl auf Leinwand",
      workNo: "857",
      year: "1987/07",
    },
    images: ["857_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Dunkler Rest",
      description: "130 x 100",
      technique: "Öl auf Leinwand",
      workNo: "864",
      year: "1987/07",
    },
    images: ["864_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "62 x 111",
      technique: "Öl auf Karton",
      workNo: "886",
      year: "1980",
    },
    images: ["886_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
];

//* add selected works items
work19801990.push(
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Ölkreide",
      workNo: "",
      year: "1985",
    },
    images: ["30 x 30_Ölkreide_85.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 20",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1985",
    },
    images: ["30x20_Öl auf Papier_85.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 24",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1985",
    },
    images: ["30x24_Öl auf Papier_85.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1985",
    },
    images: ["30x30_Öl auf Papier_85.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1980",
    },
    images: ["30x30_Ölkreide_Papier_Collage_80.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 40",
      technique: "Öl auf Karton",
      workNo: "",
      year: "1985",
    },
    images: ["30x40_Öl auf Karton_85.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 40",
      technique: "Öl auf Leinwand",
      workNo: "",
      year: "1980",
    },
    images: ["30x40_Öl auf Leinwand_80.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 40",
      technique: "Öl auf Leinwand",
      workNo: "",
      year: "1985",
    },
    images: ["30x40_öl auf Leinwand_85.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "45 x 65",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1985",
    },
    images: ["45x65_ÖPC_85.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "61 x 44",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1985",
    },
    images: ["61x44_Öl aud Papier_85.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  }
);

export default work19801990;
