//* third party packages
import React from "react";

//* components
import * as s from "components/Apps/Main/Pages/Pages.styled";

//* other

const Bibliographie: React.FunctionComponent = () => {
  return (
    <s.TextPageContentContainer>
      <s.TextPageHeadline>Literatur ( Auswahl )</s.TextPageHeadline>
      <s.TextPageScrollContainer>
        <s.TwoColWrapper>
          {/* prettier-ignore */}
          <s.TwoColParagraph>
              <b>Sehr sakral</b> in Der Spiegel Nr.26 1971<br />
              Klaus Ulrich Reinke <b>Innen und Außen</b> für WDR Mosaik II 7.April 1975<br />
              Gislind Nabakowski <b>Es soll nicht echt sein, Landschaft...</b><br />
              in Musik und Medizin 6/1977<br />
              <b>Fragmentarisches von Frackmann</b> in Lady International 8/1977<br />
              <b>Ölbilder Aquarelle Lyrik</b> von Frackmann in Das Kunstmagazin 2/1978<br />
              Yvonne Friedrichs <b>Wortlose Poesie</b> in Rheinische Post 3/1980<br />
              Frackmann <b>Schauplatz</b> Bilder und Notizen Katalog Hamburg 1981<br />
              Frackmann <b>Lustgarten der Mütze</b> Fotografien Liedtitel Zeichnungen<br />
              Text: H.T.Lehmann <b>Randnotizen</b> Katalog Hamburg 1983<br />
              Frackmann <b>Zeichnungen 1978-1983</b> Katalog Zehnthaus Jockrim 1983<br />
              Frackmann <b>Bilder 1978-1983</b> Katalog Standpunkte Hamburger Kunsthalle mit Texten von Werner Hofmann Herbert C.Lewinsky Helmut R. Leppin Hamburg 1983<br />
              Frackmann <b>Rucksack</b> Lehmann <b>Schriften</b> Zeichnungen und Texte CC-Verlag Hamburg 1983 ISBN 3-924349-00-2<br />
              Ulrich Bischoff (Hrsg) <b>Im toten Winkel</b> Katalogbuch Kunstverein Hamburg 1984<br />
              Frackmann <b>Steinkreuz Altarbild Klangstück Skulptur</b> Katalog Gnadenkirche St.Pauli Nord 1984<br />
              Michael Hübl <b>Frackmann und anderes in Schwarz.Sinnpause</b> Kunstforum International 80/1985<br />
              Hartmut Winde <b>Ein Altarbild Ein Skandal</b> in Kunst und Kirche 3/1985<br />
              Frackmann <b>Prometheus bringt die Erde dem Feuer</b> Katalog Hamburg 1987<br />
              GAK Bremen <b>Der Raum</b> Frackmann: <b>Die Wiederentdeckung der unsozialen Skulptur....</b>1987<br />
              David Galloway (Hrsg) <b>Artware Kunst und Elektronik</b> Econ Verlag Düsseldorf ISBN 3-430-13007-1<br />
              Ursula Peters+Georg F.Schwarzbauer (Hrsg) <b>Vom Essen und Trinken</b> Darstellungen in der Kunst der Gegenwart Museumsverein Wuppertal 1987<br />
              Andreas Martin <b>Schön Heilig Schrecklich</b> Frackmann Environment Gnadenkirche Hamburg in Kirche und moderne Kunst Frankfurt 1988<br />
              Georg Bussmann (Hrsg) <b>Arbeit in Geschichte Geschichte in Arbeit</b> Nishen Verlag Berlin 1988 ISBN 3889400337<br />
              Helmut R.Leppien <b>The dark is light enough</b> in Katalog Frackmann Hamburg Kunsthalle 1988<br />
              Karlheinz Schmid <b>Harald Frackmann Hamburger Kunsthalle auf Kampnagel</b> Kunstforum 97/1988<br />
              Mechthild Bausch <b>Ästhetik des Verschwindens</b> Hamburger Rundschau 22.September1988<br />
              Frackman n <b>Aus den Aufzeichnungen eines Artisten.Menu Noir</b>  Texte und Zeichnungen<br />
            </s.TwoColParagraph>
          {/* prettier-ignore */}
          <s.TwoColParagraph>
              <b>1.September 4:45</b> 1939 KX Katalog Hamburg 1989<br />
              Frackmann <b>Argus Trauben</b> Malerei Zeichnung Skulptur Katalog Hamburg 1990<br />
              Peter Thurmann <b>Über Harald Frackmanns Zeichnungen und Panoramen</b> Kunsthalle zu Kiel/Christian-Albrecht-Universität 1992 ISBN 3-923701-55-1<br />
              Hartmut Winde <b>Frackmann Steinkreuz Friede der Passion, Altarbild Feuerskulptur</b> in Kunst und Sakrament 1992 ISBN 3-923974-12-4<br />
              Ulrich Schneider <b>Schwarz sehen, Schwarz hören Harald Frackmann und der Humor</b> in Katalog Suermondt-Ludwig-Museum Aachen Bilder und Reste einer Weltausstellung 1992<br />
              Gunnar F,Gerlach <b>Aus der Welt still schöpfen</b> im Katalog ebenda<br />
              Gernot Geduldig <b>Nie ein Ende finden</b> Suermondt-Ludwig-Museum zeigt Bilder von Frackmann AN Kultur und Gesellschaft Aachen 3.April 1992<br />
              Eckhard Hoog <b>Blut aus der Ofenfabrik SLM zeigt Frackmann</b> AVZ Aachen 3.April 1992 Nr.80<br />
              Marianne Greve (Hrsg) <b>Poiesis Rituale vergessen Inhalte verloren</b> Botanisches Museum Hamburg Verlag Boyens + Co 1994 ISBN 3-8042-0779-0<br />
              <b>Kunststreifzüge</b> Norddeutscher Rundfunk Hamburgische Kulturstiftung Kunsthaus Hamburg (Hrsg) Dölling und Galwitz Verlag München Hamburg 1995<br />
              Hans Thies Lehmann <b>Noblesse der Fassung Landschaftsmasken.Zu Frackmanns Bildern</b> in Katalog St.Annen-Museum Lübeck In Memoriam Gerhard Gerkens<br />
              <b>Tempus fugit</b> 2000 ISBN 3-9803004-6-3<br />
              Carl Aigner/Helmut Zambo (Hrsg) <b>Johann Hauser Im Hinterland des Herzens</b> Kunsthalle Krems Verlag Christian Brandstätter Wien-München 2001 ISBN 3-85498-096-5<br />
              Thomas Kurzt <b>Realität in gemalte Bilder überführen</b> Bilder von Frackmann im Pforzheimer Kunstverein Pforzheimer Zeitung 9.Februar 2002<br />
              Stephanie von Neuhoff <b>Der spannende Blick ins Dunkle</b> Hamburger Abendblatt 29.November 2006<br />
              Robert Morat <b>Die Fotografie des Malers</b> Katalog Museum für Kunst und Gewerbe Hamburg Frackmann Fotografie 1970-2006 ISBN 923859-67-8<br />
              Claus Clement <b>schönwahnsinnig</b> Zeitgenössische Kunst auf Papier Aus der Sammlung CC Edition Braus Frankfurt ISBN 978-3-89904-262-7<br />
              Martina Gross/Patrick Primavesi (Hrsg) <b>Lücken sehen</b> Beiträge zu Theater Literatur und Performance Universitätsverlag Winter Heidelberg 2010 ISBN 978-3-8253-5777-1<br />
            </s.TwoColParagraph>
        </s.TwoColWrapper>
      </s.TextPageScrollContainer>
    </s.TextPageContentContainer>
  );
};

export default Bibliographie;
