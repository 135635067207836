import styled from "styled-components/macro";

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-left: initial;
    justify-content: center;
    align-items: center;
    width: 100%;
    & > :not(:last-child) {
      margin-bottom: 33.78px;
    }
  }
`;

export const FilterText = styled.div`
  display: flex;
  font-family: "Open-Sans Bold", serif;
  font-size: 15px;
  left: 0;
  line-height: 14px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  text-align: center;
  
  top: 20px;
  width: 93px;
`;

export const FilterContainer = styled.div`
  display: flex;
  width: 34px;
  height: 30px;
`;
