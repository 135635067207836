//* third party packages
import { KontaktImpressumWrapper } from "components/Apps/Main/Pages/Pages.styled";
import React from "react";

//* components
import * as s from "components/Apps/Main/Pages/Pages.styled";
import * as links from "components/Apps/Main/Components/Links";
import Icons from "components/Apps/Main/Pages/Kontakt/Icons";

//* other
import FrackmannKontaktImage from "assets/images/pages/frackmann_kontakt.png";

const Kontakt: React.FunctionComponent = () => {
  return (
    <s.ImagePageScrollContainer>
      <s.ImagePageContentContainer>
        <s.ImagePageContentWrapper>
          <s.KontaktImageWrapper>
            <s.KontaktImage src={FrackmannKontaktImage} title={""} alt={""} />
          </s.KontaktImageWrapper>
          <s.KontaktTextWrapper>
            <s.TextPageHeadline>Kontakt</s.TextPageHeadline>
            <s.KontaktWennDuWrapper>
              Bei näherem Interesse freuen wir uns, einen Termin im Atelier mit
              Ihnen vereinbaren zu dürfen. Schreiben Sie uns dazu und zu
              weiteren Anfragen bitte eine E-Mail.
            </s.KontaktWennDuWrapper>
            <s.KontaktLinkWrapper>
              <Icons />
              <s.KontaktIconTextContainer>
                <div>
                  <links.Mail>hfrackmann@gmx.de</links.Mail>
                </div>
                <div>
                  <links.Instagram>harald_frackmann</links.Instagram>
                </div>
                {/*<div>*/}
                {/*  <links.TikTok>frackmann</links.TikTok>*/}
                {/*</div>*/}
                <div>
                  <links.Facebook>Harald Frackmann</links.Facebook>
                </div>
              </s.KontaktIconTextContainer>
            </s.KontaktLinkWrapper>
            <s.KontaktImpressumWrapper>
              <p>
                Der Inhalt dieser Website (Texte, Bilder, Fotografien)
                unterliegt dem Schutz des Urheberrechts und darf nicht zu
                kommerziellen Zwecken kopiert, verbreitet, verändert oder
                Dritten zugänglich gemacht werden. Für alle rechtswidrigen
                Verlinkungen und deren Inhalt wird keine Verantwortung
                übernommen.
              </p>
              <p>
                Wenn Sie Fragen zum Impressum haben, schreiben Sie uns bitte
                eine E-Mail: hfrackmann@gmx.de
              </p>
            </s.KontaktImpressumWrapper>
          </s.KontaktTextWrapper>
        </s.ImagePageContentWrapper>
      </s.ImagePageContentContainer>
    </s.ImagePageScrollContainer>
  );
};

export default Kontakt;
