const selectedWorks = [
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "21 x 31",
      technique: "Öl auf Print",
      workNo: "",
      year: "1990",
    },
    images: ["21x31_Öl auf Print_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "23 x 33",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["23x33_ÖPC_90---.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "23 x 40",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["23x40_Öl auf Papier_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "25 x 30",
      technique: "Öl auf Karton",
      workNo: "",
      year: "",
    },
    images: ["25x30x5_Öl auf Karton.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "25 x 35",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["25x35_Öl auf Papier_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "28 x 40",
      technique: "Öl auf Pappe",
      workNo: "",
      year: "1990",
    },
    images: ["28 x 40_Öl auf Pappe_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "28 x 23",
      technique: "Öl auf Print",
      workNo: "",
      year: "1990",
    },
    images: ["28x23_Öl au f Print_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "29 x 33",
      technique: "Papier Collage",
      workNo: "",
      year: "1990",
    },
    images: ["29x33_Papier Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "29 x 43",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["29x43_Öl uaf Papier Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Öl auf Karton",
      workNo: "",
      year: "1990",
    },
    images: ["30 x 30_Öl auf Karton_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Ölkreide",
      workNo: "",
      year: "1985",
    },
    images: ["30 x 30_Ölkreide_85.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 20",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1985",
    },
    images: ["30x20_Öl auf Papier_85.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 23",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x23_ÖPC_90--.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 23",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x23_ÖPC_90___.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 24",
      technique: "Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x24_Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 24",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1985",
    },
    images: ["30x24_Öl auf Papier_85.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 24",
      technique: "Öl auf Print",
      workNo: "",
      year: "1995",
    },
    images: ["30x24_Öl auf Print_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 24",
      technique: "Öl Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x24_Öl_Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 25",
      technique: "Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x25_Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 25",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1995",
    },
    images: ["30x25_Öl auf Papier_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 25",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x25_Öl Papier Collage-90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 25",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x25_ÖPC_90__.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 28",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["30x28_Öl auf Papier_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x30_Öl auf Papier Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x30_Öl auf Papier Collage_90_.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1985",
    },
    images: ["30x30_Öl auf Papier_85.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["30x30_Öl auf Papier_90-.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1995",
    },
    images: ["30x30_Öl auf Papier_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Ölfarbe Tusche",
      workNo: "",
      year: "1990",
    },
    images: ["30x30_Ölfarbe Tusche_90___.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Ölkreide auf Papier",
      workNo: "",
      year: "1975",
    },
    images: ["30x30_Ölkreide auf Papier_75.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Ölkreide auf Papier",
      workNo: "",
      year: "1979",
    },
    images: ["30x30_Ölkreide auf Papier_79.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Ölkreide auf Papier",
      workNo: "",
      year: "1979",
    },
    images: ["30x30_Ölkreide auf Papier_79_.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1980",
    },
    images: ["30x30_Ölkreide_Papier_Collage_80.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x30_ÖPC_90-.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 40",
      technique: "Öl auf Karton",
      workNo: "",
      year: "1985",
    },
    images: ["30x40_Öl auf Karton_85.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 40",
      technique: "Öl auf Leinwand",
      workNo: "",
      year: "",
    },
    images: ["30x40_Öl auf Leinwand.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 40",
      technique: "Öl auf Leinwand",
      workNo: "",
      year: "1980",
    },
    images: ["30x40_Öl auf Leinwand_80.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 40",
      technique: "Öl auf Leinwand",
      workNo: "",
      year: "1985",
    },
    images: ["30x40_öl auf Leinwand_85.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 40",
      technique: "Öl auf Leinwand",
      workNo: "",
      year: "1990",
    },
    images: ["30x40_Öl auf Leinwand_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 40",
      technique: "Öl auf Leinwand",
      workNo: "",
      year: "1990",
    },
    images: ["30x40_Öl auf Leinwand_90_.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 40",
      technique: "Öl auf Leinwand",
      workNo: "",
      year: "1995",
    },
    images: ["30x40_Öl auf Leinwand_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 40",
      technique: "Ölkreide auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["30x40_Ölkreide auf Papier_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "31 x 24",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["31x24_Öl auf Papier_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "31 x 25",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1995",
    },
    images: ["31x25_Öl auf Papier_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "31 x 25",
      technique: "Öl auf Karton",
      workNo: "",
      year: "1995",
    },
    images: ["31x25x10_Öl auf Karton_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "32 x 24",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["32x24_ÖPC_90--.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "32 x 25",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["32x25_ÖPC90___.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "33 x 23",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["33x23_ÖCP_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "33 x 23",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["33x23_ÖPC_90__.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "33 x 23",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1995",
    },
    images: ["33x23_ÖPC_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "33 x 24",
      technique: "Öl auf Print",
      workNo: "",
      year: "1990",
    },
    images: ["33x24_Öl auf Print_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "33 x 24",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1995",
    },
    images: ["33x24_ÖPC_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "33 x 24",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1995",
    },
    images: ["33x24_ÖPC_95__.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "35 x 24",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["35x24_ÖPC_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "35 x 24",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["35x24_OPC_90__.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "35 x 25",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1995",
    },
    images: ["35x25_Öl auf Papier_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "35 x 25",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["35x25_Öl auf Papier_Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "35 x 25",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1995",
    },
    images: ["35x25_ÖPC_95_.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "35 x 38",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1995",
    },
    images: ["35x38_ÖPC_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "35 x 45",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["35x45_Öl auf Papier_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "38 x 29",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1995",
    },
    images: ["38x29_Öl auf Papier_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "39 x 21",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["39x21_Öl auf Papier_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "40 x 30",
      technique: "Öl auf Leinwand",
      workNo: "",
      year: "1990",
    },
    images: ["40x30_Öl auf Leinwand_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "42 x 24",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["42x24_Öl auf Papier_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "44 x 30",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["44x30_Öl Papier Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "44 x 62",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1979",
    },
    images: ["44x62_Öl auf Papier_79.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "44 x 62",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["44x62_ÖPC_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "45 x 30",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["45x30_Öl Papier Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "45 x 60",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1995",
    },
    images: ["45x60_Öl auf Papier_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "45 x 65",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1985",
    },
    images: ["45x65_ÖPC_85.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "61 x 44",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1985",
    },
    images: ["61x44_Öl aud Papier_85.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "",
      technique: "",
      workNo: "",
      year: "1999",
    },
    images: ["DSCF1999.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 14,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
];

const others = [
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "148 x 100",
      technique: "Öl auf Molton",
      workNo: "278",
      year: "1975",
    },
    images: ["278_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Opium Fries",
      description: "200 x 75",
      technique: "Öl auf Holz",
      workNo: "121",
      year: "1970/85",
    },
    images: ["121_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "104 x 74",
      technique: "Öl auf Holz",
      workNo: "884",
      year: "1974",
    },
    images: ["884_c.jpg"],
    imagesSubPath: ["1970-1980"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Notiz",
      description: "227 x 207",
      technique: "Öl auf Leinwand",
      workNo: "4",
      year: "1980/86",
    },
    images: ["4_Cut_DSCF0009.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Leinwand",
      workNo: "387",
      year: "1980",
    },
    images: ["387_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Erster Schnee",
      description: "100 x 150",
      technique: "Öl auf Hartfaser",
      workNo: "134",
      year: "",
    },
    images: ["134_c.jpg"],
    imagesSubPath: ["1980-1990"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Garten",
      description: "150 x 214",
      technique: "Öl auf Leinwand",
      workNo: "302",
      year: "1991",
    },
    images: ["302_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "440",
      year: "1996",
    },
    images: ["440_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "655",
      year: "1994",
    },
    images: ["655_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "814",
      year: "1994",
    },
    images: ["814_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Kopf",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "566",
      year: "1993/94",
    },
    images: ["566_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Leinwand",
      workNo: "386",
      year: "1991",
    },
    images: ["386_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "333",
      year: "1991",
    },
    images: ["333_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Mondlandschaft",
      description: "200 x 90",
      technique: "Öl auf Holz",
      workNo: "211",
      year: "1994",
    },
    images: ["211_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Tümpel",
      description: "200 x 86",
      technique: "Öl auf Holz",
      workNo: "187",
      year: "",
    },
    images: ["187_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "50 x 100",
      technique: "Öl auf Hartfaser",
      workNo: "888",
      year: "2002/03",
    },
    images: ["888_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "85 x 74",
      technique: "Öl auf Karton",
      workNo: "803",
      year: "2010",
    },
    images: ["803_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "50 x 75",
      technique: "Öl auf Karton",
      workNo: "762",
      year: "2002/03",
    },
    images: ["762_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "50 x 75",
      technique: "Öl auf Karton",
      workNo: "754",
      year: "2003/03",
    },
    images: ["754_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "90 x 72",
      technique: "Öl auf Karton",
      workNo: "692",
      year: "",
    },
    images: ["692_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 80",
      technique: "Öl auf Karton",
      workNo: "676",
      year: "2011",
    },
    images: ["676_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "609",
      year: "2002",
    },
    images: ["609_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Gartenstück",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "454",
      year: "2003",
    },
    images: ["454_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "434",
      year: "2003/04",
    },
    images: ["434_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "180 x 190",
      technique: "Öl auf Karton",
      workNo: "150",
      year: "2000/10",
    },
    images: ["150_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Reusengebiet",
      description: "200 x 70",
      technique: "Öl auf Holz",
      workNo: "160",
      year: "",
    },
    images: ["160_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "50 x 100",
      technique: "Öl auf Hartfaser",
      workNo: "889",
      year: "2003",
    },
    images: ["889_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 35,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "660",
      year: "2009",
    },
    images: ["660_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 150",
      technique: "Öl auf Leinwand",
      workNo: "286",
      year: "2008",
    },
    images: ["286_c.jpg"],
    imagesSubPath: ["2000-2020"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
];

let i = 0;
for (const other of others) {
  selectedWorks.splice(i, 0, other);
  i += 3;
}

export default selectedWorks;
