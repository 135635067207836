import styled from "styled-components/macro";

export const Container = styled.div`
  bottom: 45px;
  display: flex;
  font-family: "Open-Sans", serif;
  font-size: 15px;
  font-style: italic;
  height: 20px;
  justify-content: center;
  line-height: 20.43px;
  pointer-events: none;
  position: absolute;
  width: 100%;
  @media (max-width: 991px) {
    display: none;
  }
`;
