//* third party packages
import React from "react";

export const Mail: React.FunctionComponent<TReactPropsWithChildren> = (
  props
) => {
  return (
    <a href={"mailto:hfrackmann@gmx.de"} rel="noreferrer" title={"E-Mail"}>
      {props.children}
    </a>
  );
};

export const Instagram: React.FunctionComponent<TReactPropsWithChildren> = (
  props
) => {
  return (
    <a
      href={"https://www.instagram.com/harald_frackmann/"}
      target={"_blank"}
      rel="noreferrer"
      title={"Instagram"}
    >
      {props.children}
    </a>
  );
};

export const Facebook: React.FunctionComponent<TReactPropsWithChildren> = (
  props
) => {
  return (
    <a
      href={"https://www.facebook.com/people/Harald-Frackmann/100090174172593/"}
      target={"_blank"}
      rel="noreferrer"
      title={"Facebook"}
    >
      {props.children}
    </a>
  );
};

export const TikTok: React.FunctionComponent<TReactPropsWithChildren> = (
  props
) => {
  return (
    <a
      href={"https://www.tiktok.com/@frackmann"}
      target={"_blank"}
      rel="noreferrer"
      title={"TikTok"}
    >
      {props.children}
    </a>
  );
};
