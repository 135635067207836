import styled from "styled-components/macro";
import { SVGDirectHover } from "components/Apps/Main/styles";

export const FooterContainer = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  font-size: 9px;
  height: 28px;
  justify-content: center;
  line-height: 12.26px;
  position: absolute;
  width: 100%;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const FooterTabletContainer = styled.div`
  display: none;
  @media (max-width: 991px) {
    height: 63px;
    padding-bottom: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 9px;
    justify-content: center;
    line-height: 12.26px;
    width: 100%;
  }
`;

export const Copyright = styled.div`
  @media (max-width: 991px) {
    margin-top: 18px;
  }
`;

export const IconContainer = styled.div`
  ${SVGDirectHover};
  height: 20px;
  display: flex;
  & svg {
    height: 100%;
  }
  & a:not(:last-child) {
    margin-right: 13px;
  }
`;
