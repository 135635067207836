import styled, { keyframes } from "styled-components/macro";
import { SVGDirectHover, SVGHover } from "components/Apps/Main/styles";
import { FooterContainer } from "components/Apps/Main/Components/Footer/Footer.styled";

export const ImageDetailOverlay = styled.div<{
  $visible: boolean;
  $visibility: boolean;
}>`
  ${SVGDirectHover};
  background-color: white;
  height: calc(var(--doc-height) - var(--navbar-height) - var(--footer-height));
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  overflow: initial;
  pointer-events: ${({ $visible }) => ($visible ? "all" : "none")};
  position: absolute;
  transition: opacity 0.3s;
  visibility: ${({ $visibility }) => ($visibility ? "visible" : "hidden")};
  width: calc(100vw);
  z-index: 2;
  @media (max-width: 991px) {
    height: var(--doc-height);
    top: 0;
    margin: 0;
    & p:first-child {
      margin-top: 10px;
    }
  }
  @media (min-width: 992px) {
    & > :nth-child(2) {
      display: none;
    }
  }
  .react-slideshow-container > svg:first-child {
    margin-left: 49px;
    @media (max-width: 991px) {
      margin-left: 22px;
    }
  }
  .react-slideshow-container > svg:last-child {
    margin-right: 49px;
    @media (max-width: 991px) {
      margin-right: 22px;
    }
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: calc(var(--doc-height) - var(--navbar-height) - var(--footer-height));
  justify-content: center;
  width: 100vw;
  @media (max-width: 991px) {
    height: var(--doc-height);
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  & img {
    max-width: 78vw;
    max-height: calc(
      (var(--doc-height) - var(--navbar-height) - var(--footer-height)) / 100 *
        85
    );
    @media (max-width: 1390px) {
      max-width: 60vw;
    }
    @media (max-width: 991px) {
      max-width: 80vw;
    }
    @media (max-width: 575px) {
      max-width: calc(100vw - 44px);
    }
  }
  & > :last-child {
    display: flex;
    flex-direction: column;
  }
`;

export const BackToGalleryContainer = styled.div`
  ${SVGHover};
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 32px;
  position: absolute;
  right: 22px;
  top: 0;
  & > svg {
    height: 100%;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;

export const BackToGalleryText = styled.div`
  display: flex;
  font-family: "Open-Sans Bold", serif;
  font-size: 15px;
  line-height: 14px;
  margin-left: 0;
  text-align: left;
  width: 120px;
`;

export const MoreOfZyklusContainer = styled.div<{ $visible: boolean }>`
  ${SVGHover};
  align-items: center;
  bottom: 50px;
  cursor: pointer;
  display: flex;
  font-family: "Open-Sans", serif;
  font-size: 15px;
  justify-content: space-between;
  line-height: 20.43px;
  margin-left: 18px;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  position: absolute;
  right: -240px;
  text-align: left;
  transition: opacity 0.5s;
  width: 220px;
  &.wide {
    @media (min-width: 992px) {
      right: 0;
      bottom: 5px;
    }
  }
  &.medium {
    @media (max-width: 1200px) {
      width: 150px;
      right: -170px;
      & div:first-child {
        margin-right: 6px;
      }
    }
  }
  & div:first-child {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 991px) {
    bottom: initial;
    font-size: 11px;
    height: 40px;
    line-height: 10px;
    right: -62px;
    top: 20px;
    width: 74px;
    z-index: 10;
    &.medium {
      width: 74px;
      right: -62px;
      & div:first-child {
        margin-right: 6px;
      }
    }
    & div:last-child {
      margin-left: 7px;
    }
  }
  @media (max-width: 665px) {
    align-self: flex-end;
    position: initial;
    width: 116px;
    margin-left: 0;
    &.medium {
      width: 116px;
      & div:first-child {
        margin-right: initial;
      }
    }
  }
`;

export const FooterImageDetail = styled(FooterContainer)`
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 991px) {
    display: flex;
  }
`;

const SwipeFadeOut = keyframes`
   from {
     opacity: 1;
   }
   to {
     opacity: 0;
   }
`;

export const SwipeHintContainer = styled.div<{ $remove: boolean }>`
  align-items: center;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-name: ${({ $remove }) => ($remove ? SwipeFadeOut : "initial")};
  bottom: 46px;
  display: none;
  height: 44.75px;
  justify-content: space-between;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  width: 100px;
  @media (max-width: 575px) {
    display: flex;
  }
  & > svg *[fill] {
    fill: #c6c6c6 !important;
  }
  & > svg *[stroke] {
    stroke: #c6c6c6 !important;
  }
  & > div {
    color: #c6c6c6 !important;
    margin-left: 18.2px;
    width: 46px;
    font-family: "Open-Sans Bold", serif;
    font-size: 11px;
    line-height: 10px;
  }
`;
