const work19902000 = [
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 80",
      technique: "Öl auf Holz",
      workNo: "41",
      year: "1993",
    },
    images: ["41_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 75",
      technique: "Öl auf Karton",
      workNo: "105",
      year: "1999/01",
    },
    images: ["105_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 75",
      technique: "Öl auf Karton",
      workNo: "106",
      year: "1999/01",
    },
    images: ["106_C.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 75",
      technique: "Öl auf Karton",
      workNo: "112",
      year: "1999/01",
    },
    images: ["112_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Verbrauchte Momente",
      description: "200 x 75",
      technique: "Öl auf Karton",
      workNo: "117",
      year: "1999/01",
    },
    images: ["117_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 75",
      technique: "Öl auf Karton",
      workNo: "120",
      year: "1999/01",
    },
    images: ["120_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 80",
      technique: "Öl auf Karton",
      workNo: "128",
      year: "1993",
    },
    images: ["128_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "172 x 63",
      technique: "Öl auf Holz",
      workNo: "130",
      year: "",
    },
    images: ["130_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "142 x 112",
      technique: "Öl auf Holz",
      workNo: "136",
      year: "1990",
    },
    images: ["136_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "80 x 112",
      technique: "Öl auf Holz",
      workNo: "146",
      year: "1995",
    },
    images: ["146_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "200 x 70",
      technique: "Öl auf Holz",
      workNo: "161",
      year: "1997/98",
    },
    images: ["161_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Dunkles Land",
      description: "200 x 86",
      technique: "Öl auf Holz",
      workNo: "169",
      year: "1995/96",
    },
    images: ["169_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Deponie",
      description: "200 x 86",
      technique: "Öl auf Holz",
      workNo: "173",
      year: "1993/94",
    },
    images: ["173_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Waldstück",
      description: "200 x 86",
      technique: "Öl auf Holz",
      workNo: "174",
      year: "1993",
    },
    images: ["174_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Großer Schuh",
      description: "200 x 86",
      technique: "Öl auf Holz",
      workNo: "175",
      year: "1990",
    },
    images: ["175_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Wuchs",
      description: "200 x 86",
      technique: "Öl auf Holz",
      workNo: "178",
      year: "1994",
    },
    images: ["178_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Wasser",
      description: "200 x 86",
      technique: "Öl auf Holz",
      workNo: "180",
      year: "1990",
    },
    images: ["180_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Nasses Gehänge",
      description: "200 x 86",
      technique: "Öl auf Holz",
      workNo: "181",
      year: "1998",
    },
    images: ["181_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Tümpel",
      description: "200 x 86",
      technique: "Öl auf Holz",
      workNo: "187",
      year: "",
    },
    images: ["187_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Mondlandschaft",
      description: "200 x 90",
      technique: "Öl auf Holz",
      workNo: "211",
      year: "1994",
    },
    images: ["211_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Unterholz",
      description: "200 x 90",
      technique: "Öl auf Holz",
      workNo: "212",
      year: "1999",
    },
    images: ["212_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Dunkles Land",
      description: "200 x 90",
      technique: "Öl auf Holz",
      workNo: "231",
      year: "1995/96",
    },
    images: ["231_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Liegendes Tier",
      description: "200 x 90",
      technique: "Öl auf Holz",
      workNo: "232",
      year: "1995",
    },
    images: ["232_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Deponie",
      description: "200 x 90",
      technique: "Öl auf Holz",
      workNo: "234",
      year: "",
    },
    images: ["234_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Tümpel",
      description: "200 x 90",
      technique: "Öl auf Holz",
      workNo: "235",
      year: "1993",
    },
    images: ["235_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Mauertier",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "244",
      year: "1997",
    },
    images: ["244_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Fluss",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "254",
      year: "1993/94",
    },
    images: ["254_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Tierreste",
      description: "154 x 105",
      technique: "Öl auf Molton",
      workNo: "273",
      year: "1997",
    },
    images: ["273_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "154 x 105",
      technique: "Öl auf Molton",
      workNo: "274",
      year: "1997",
    },
    images: ["274_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Moos",
      description: "Ø124",
      technique: "Öl auf Holz",
      workNo: "283",
      year: "1992",
    },
    images: ["283_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Garten",
      description: "215 x 145",
      technique: "Öl auf Leinwand",
      workNo: "284",
      year: "1991",
    },
    images: ["284_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "215 x 145",
      technique: "Öl auf Leinwand",
      workNo: "287",
      year: "1990/91",
    },
    images: ["287_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "215 x 145",
      technique: "Öl auf Leinwand",
      workNo: "289",
      year: "1990/91",
    },
    images: ["289_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Garten",
      description: "150 x 219",
      technique: "Öl auf Leinwand",
      workNo: "297",
      year: "1991",
    },
    images: ["297_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Garten",
      description: "150 x 219",
      technique: "Öl auf Leinwand",
      workNo: "299",
      year: "1991",
    },
    images: ["299_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Garten",
      description: "150 x 219",
      technique: "Öl auf Leinwand",
      workNo: "300",
      year: "1991",
    },
    images: ["300_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Garten",
      description: "150 x 214",
      technique: "Öl auf Leinwand",
      workNo: "301",
      year: "1991",
    },
    images: ["301_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Garten",
      description: "150 x 214",
      technique: "Öl auf Leinwand",
      workNo: "302",
      year: "1991",
    },
    images: ["302_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 60,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "120 x 100",
      technique: "Öl auf Leinwand",
      workNo: "305",
      year: "",
    },
    images: ["305_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "160 x 120",
      technique: "Öl auf Holz",
      workNo: "330",
      year: "1990",
    },
    images: ["330_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "332",
      year: "1993/94",
    },
    images: ["332_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "333",
      year: "1991",
    },
    images: ["333_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "336",
      year: "1991",
    },
    images: ["336_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 150",
      technique: "Öl auf Karton",
      workNo: "338",
      year: "1993",
    },
    images: ["338_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Rand einer Deponie",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "342",
      year: "1997",
    },
    images: ["342_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Fall & Schrei",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "343",
      year: "1993/94",
    },
    images: ["343_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Am Fluss",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "344",
      year: "1997",
    },
    images: ["344_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Wasser Larven",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "350",
      year: "1997",
    },
    images: ["350_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "354",
      year: "1994",
    },
    images: ["354_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 150",
      technique: "Öl auf Karton",
      workNo: "355",
      year: "1991",
    },
    images: ["355_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 40,
    scaleVerticalTablet: 45,
    scaleVerticalMobile: 50,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "358",
      year: "1997",
    },
    images: ["358_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Leinwand",
      workNo: "384",
      year: "1991",
    },
    images: ["384_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Leinwand",
      workNo: "385",
      year: "1991",
    },
    images: ["385_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Leinwand",
      workNo: "386",
      year: "1991",
    },
    images: ["386_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "389",
      year: "1999",
    },
    images: ["389_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Depression",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "400",
      year: "1993/94",
    },
    images: ["400_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "401",
      year: "",
    },
    images: ["401_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Hasen",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "420",
      year: "1999",
    },
    images: ["420_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Hartfaser",
      workNo: "426",
      year: "",
    },
    images: ["426_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Teil einer Deponie",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "432",
      year: "1999",
    },
    images: ["432_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "440",
      year: "1996",
    },
    images: ["440_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "441",
      year: "1997",
    },
    images: ["441_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Flucht",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "443",
      year: "1999",
    },
    images: ["443_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "529",
      year: "1999",
    },
    images: ["529_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "558",
      year: "1999",
    },
    images: ["558_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Kopf",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "566",
      year: "1993/94",
    },
    images: ["566_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "568",
      year: "1994",
    },
    images: ["568_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "573",
      year: "1994",
    },
    images: ["573_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "576",
      year: "1994",
    },
    images: ["576_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "577",
      year: "1999",
    },
    images: ["577_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "580",
      year: "1999",
    },
    images: ["580_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Hartfaser",
      workNo: "582",
      year: "",
    },
    images: ["582_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Dunkler Hügel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "583",
      year: "1993/94",
    },
    images: ["583_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Mutter mit Kind im Gras",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "592",
      year: "1999",
    },
    images: ["592_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "598",
      year: "1993/94",
    },
    images: ["598_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "80 x 100",
      technique: "Öl auf Leinwand",
      workNo: "601",
      year: "1997",
    },
    images: ["601_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "606",
      year: "",
    },
    images: ["606_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "611",
      year: "1994/02",
    },
    images: ["611_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "612",
      year: "1994/02",
    },
    images: ["612_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "613",
      year: "1994/02",
    },
    images: ["613_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Kleiner Sohn",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "616",
      year: "1999",
    },
    images: ["616_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "618",
      year: "1994",
    },
    images: ["618_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "655",
      year: "1994",
    },
    images: ["655_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "657",
      year: "1994",
    },
    images: ["657_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "714",
      year: "1998",
    },
    images: ["714_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Berglust",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "727",
      year: "1999",
    },
    images: ["727_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Loch in Deponie",
      description: "75 x 100",
      technique: "Öl auf Karton",
      workNo: "743",
      year: "1993/94",
    },
    images: ["743_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "100 x 75",
      technique: "Öl auf Karton",
      workNo: "744",
      year: "1994/02",
    },
    images: ["744_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 25,
    scaleVerticalTablet: 25,
    scaleVerticalMobile: 30,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "814",
      year: "1994",
    },
    images: ["814_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "150 x 100",
      technique: "Öl auf Karton",
      workNo: "816",
      year: "1993",
    },
    images: ["816_c.jpg"],
    imagesSubPath: ["1990-2000"],
    scaleHorizontal: 30,
    scaleVerticalTablet: 35,
    scaleVerticalMobile: 40,
  },
];

//* add selected works items
work19902000.push(
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "21 x 31",
      technique: "Öl auf Print",
      workNo: "",
      year: "1990",
    },
    images: ["21x31_Öl auf Print_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "23 x 33",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["23x33_ÖPC_90---.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "23 x 40",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["23x40_Öl auf Papier_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "25 x 35",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["25x35_Öl auf Papier_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "28 x 40",
      technique: "Öl auf Pappe",
      workNo: "",
      year: "1990",
    },
    images: ["28 x 40_Öl auf Pappe_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "28 x 23",
      technique: "Öl auf Print",
      workNo: "",
      year: "1990",
    },
    images: ["28x23_Öl au f Print_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "29 x 33",
      technique: "Papier Collage",
      workNo: "",
      year: "1990",
    },
    images: ["29x33_Papier Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "29 x 43",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["29x43_Öl uaf Papier Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Öl auf Karton",
      workNo: "",
      year: "1990",
    },
    images: ["30 x 30_Öl auf Karton_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 23",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x23_ÖPC_90--.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 23",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x23_ÖPC_90___.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 24",
      technique: "Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x24_Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 24",
      technique: "Öl auf Print",
      workNo: "",
      year: "1995",
    },
    images: ["30x24_Öl auf Print_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 24",
      technique: "Öl Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x24_Öl_Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 25",
      technique: "Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x25_Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 25",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1995",
    },
    images: ["30x25_Öl auf Papier_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 25",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x25_Öl Papier Collage-90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 25",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x25_ÖPC_90__.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 28",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["30x28_Öl auf Papier_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x30_Öl auf Papier Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x30_Öl auf Papier Collage_90_.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["30x30_Öl auf Papier_90-.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1995",
    },
    images: ["30x30_Öl auf Papier_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Ölfarbe Tusche",
      workNo: "",
      year: "1990",
    },
    images: ["30x30_Ölfarbe Tusche_90___.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 30",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["30x30_ÖPC_90-.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 40",
      technique: "Öl auf Leinwand",
      workNo: "",
      year: "1990",
    },
    images: ["30x40_Öl auf Leinwand_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 40",
      technique: "Öl auf Leinwand",
      workNo: "",
      year: "1990",
    },
    images: ["30x40_Öl auf Leinwand_90_.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 40",
      technique: "Öl auf Leinwand",
      workNo: "",
      year: "1995",
    },
    images: ["30x40_Öl auf Leinwand_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "30 x 40",
      technique: "Ölkreide auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["30x40_Ölkreide auf Papier_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "31 x 24",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["31x24_Öl auf Papier_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "31 x 25",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1995",
    },
    images: ["31x25_Öl auf Papier_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "31 x 25",
      technique: "Öl auf Karton",
      workNo: "",
      year: "1995",
    },
    images: ["31x25x10_Öl auf Karton_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "32 x 24",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["32x24_ÖPC_90--.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "32 x 25",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["32x25_ÖPC90___.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "33 x 23",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["33x23_ÖCP_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "33 x 23",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["33x23_ÖPC_90__.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "33 x 23",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1995",
    },
    images: ["33x23_ÖPC_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "33 x 24",
      technique: "Öl auf Print",
      workNo: "",
      year: "1990",
    },
    images: ["33x24_Öl auf Print_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "33 x 24",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1995",
    },
    images: ["33x24_ÖPC_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "33 x 24",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1995",
    },
    images: ["33x24_ÖPC_95__.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "35 x 24",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["35x24_ÖPC_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "35 x 24",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["35x24_OPC_90__.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "35 x 25",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1995",
    },
    images: ["35x25_Öl auf Papier_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "35 x 25",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["35x25_Öl auf Papier_Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "35 x 25",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1995",
    },
    images: ["35x25_ÖPC_95_.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "35 x 38",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1995",
    },
    images: ["35x38_ÖPC_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "35 x 45",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["35x45_Öl auf Papier_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "38 x 29",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1995",
    },
    images: ["38x29_Öl auf Papier_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "39 x 21",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["39x21_Öl auf Papier_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "40 x 30",
      technique: "Öl auf Leinwand",
      workNo: "",
      year: "1990",
    },
    images: ["40x30_Öl auf Leinwand_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "42 x 24",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1990",
    },
    images: ["42x24_Öl auf Papier_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "44 x 30",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["44x30_Öl Papier Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "44 x 62",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["44x62_ÖPC_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "45 x 30",
      technique: "Öl auf Papier/Collage",
      workNo: "",
      year: "1990",
    },
    images: ["45x30_Öl Papier Collage_90.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "45 x 60",
      technique: "Öl auf Papier",
      workNo: "",
      year: "1995",
    },
    images: ["45x60_Öl auf Papier_95.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  },
  {
    gridHorizontal: { cols: 1, rows: 1 },
    gridVerticalTablet: { cols: 1, rows: 1 },
    gridVerticalMobile: { cols: 1, rows: 1 },
    group: {
      name: "Ohne Titel",
      description: "",
      technique: "",
      workNo: "",
      year: "1999",
    },
    images: ["DSCF1999.jpg"],
    imagesSubPath: ["selected-works"],
    scaleHorizontal: 20,
    scaleVerticalTablet: 65,
    scaleVerticalMobile: 70,
  }
);

export default work19902000;
