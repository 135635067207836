import * as s from "components/Apps/Main/Components/Footer/Footer.styled";
import styled from "styled-components/macro";
import { SVGHover } from "components/Apps/Main/styles";

export const MenuContainer = styled.nav<{ open: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.color.elements.mainBackground};
  box-shadow: 0 4px 9px 0 ${({ theme }) => theme.color.elements.menuShadowColor};
  display: flex;
  height: var(--doc-height);
  justify-content: flex-start;
  position: absolute;
  right: ${({ open }) => (open ? 0 : "-375px")};
  top: 0;
  transition: right 0.3s;
  width: 375px;
  z-index: 10;
  & ${s.FooterTabletContainer} {
    display: none;
  }
  @media (max-width: 991px) {
    flex-direction: column;
    justify-content: center;
    opacity: ${({ open }) => (open ? 1 : 0)};
    pointer-events: ${({ open }) => (open ? "all" : "none")};
    right: 0;
    transition: opacity 0.3s;
    width: 100%;
    & ${s.FooterTabletContainer} {
      position: absolute;
      bottom: 0;
      display: flex;
    }
  }
`;

export const LogoContainer = styled.div`
  display: none;
  font-weight: bold;
  font-size: 18px;
  @media (max-width: 991px) {
    display: block;
    height: 30.2px;
    left: 22px;
    position: absolute;
    top: 20px;
    width: 55.77px;
  }
`;

export const CloseContainer = styled.div`
  ${SVGHover};
  cursor: pointer;
  height: 21.43px;
  left: 307px;
  position: absolute;
  top: 43.8px;
  width: 21.21px;
  @media (max-width: 991px) {
    right: 22.8px;
    top: 24px;
    left: initial;
  }
`;
